import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CustomerView from '../customers/CustomerView';
import InvoiceView from '../Invoice/InvoiceView';
import DocumentRelations from './DocumentRelations';
import Spinner from '../../components/common/Spinner';
import { DOCUMENT_TYPE } from '../../utils/util';
import { Link } from 'react-router-dom';

import { getDocumentById, downloadPDF } from './document-duck/documentAction';

export class DocumnetView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      document: {},
      loading: true,
      id: null,

      errors: {}
    };
    this.downloadPDFDocument = this.downloadPDFDocument.bind(this);
  }

  componentDidMount() {
    const {
      match: { params },
      getDocumentById
    } = this.props;
    this.setState({ id: params.id });
    getDocumentById(params.id);
  }

  downloadPDFDocument() {
    const { document } = this.state;
    let fileName =
      document.type.substring(0, 2) + '-' + document.sequence + '.pdf';
    this.props.downloadPDF(document._id, fileName);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.state.id) {
      const {
        match: { params }
      } = nextProps;
      this.setState({ id: params.id });
      this.props.getDocumentById(params.id);
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
      //redirect page not found
      this.props.history.push('/not-found');
    }
    if (nextProps.document.document) {
      const doc = nextProps.document.document;

      this.setState({ document: doc });
    }
    if (nextProps.document) {
      const doc = nextProps.document;

      this.setState({ loading: doc.loading });
    }
  }

  render() {
    const { document, loading } = this.state;
    let title = '';

    let content, options;
    if (loading) {
      content = <Spinner />;
    } else {
      if (document) {
        const customer = document.customer.customerData;
        title =
          document.type === DOCUMENT_TYPE.INVOICE
            ? 'תעודת משלוח'
            : document.type === DOCUMENT_TYPE.BID
            ? 'הצעת מחיר'
            : document.type === DOCUMENT_TYPE.RECEIPT
            ? 'קבלה'
            : document.type === DOCUMENT_TYPE.CREDIT
            ? 'זיכוי'
            : '';
        content = (
          <div>
            <CustomerView customer={customer} />
            <hr />
            <InvoiceView
              document={document}
              isAdmin={this.props.auth.user.isAdmin}
            />
          </div>
        );
        if (document.type === DOCUMENT_TYPE.BID) {
          options = (
            <div className="row m-1 p-2">
              <div className="col-sm-6">
                <Link
                  className="btn btn-large btn-block btn-info "
                  to={`/edit-bid/${document._id}`}
                >
                  <i className="fas fa-edit" /> ערוך
                </Link>
              </div>

              <div className="col-sm-6">
                <Link
                  className="btn btn-large btn-block btn-info "
                  to={`/become-invoice/${document._id}`}
                >
                  <i className="fas fa-exchange-alt" /> הפוך להזמת ביצוע
                </Link>
              </div>
            </div>
          );
        } else if (document.type === DOCUMENT_TYPE.INVOICE) {
          options = (
            <div className="row m-1 p-2">
              <div className="col-sm-6">
                <Link
                  className="btn btn-large btn-block btn-info "
                  to={`/become-credit/${document._id}`}
                >
                  <i className="fas fa-edit" /> הפוך לזיכוי
                </Link>
              </div>
              <div className="col-sm-6">
                <Link
                  className="btn btn-large btn-block btn-info "
                  to={`/create-receipt/${document._id}/${document.customer.customerData._id}`}
                >
                  <i className="fas fa-credit-card" /> הפקת קבלה
                </Link>
              </div>
            </div>
          );
        } else {
          options = '';
        }
      } else {
        content = options = <Spinner />;
      }
    }
    return (
      <div className="document">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="display-4 text-center">{title}</h1>
              <hr />
              {content}
            </div>
          </div>
          {document.relations ? (
            document.relations.length > 0 ? (
              <div className="row">
                <div className="col-md-12">
                  <br />
                  <br />
                  <DocumentRelations
                    relations={document.relations}
                    history={this.props.history}
                  />
                </div>
              </div>
            ) : (
              ''
            )
          ) : (
            ''
          )}

          {document.type === DOCUMENT_TYPE.RECEIPT ? (
            ''
          ) : (
            <div className="row">
              <div className="col-md-12 p-5">
                <button
                  onClick={this.downloadPDFDocument}
                  className="btn btn-info btn-lg btn-block text-light"
                >
                  <i className="fas fa-print" /> Print
                </button>
                {options}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

DocumnetView.propTypes = {
  auth: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  getDocumentById: PropTypes.func.isRequired,
  downloadPDF: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  document: state.document
});

export default connect(mapStateToProps, { getDocumentById, downloadPDF })(
  DocumnetView
);
