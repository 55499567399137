import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getAllProducts } from "../products/products-duck/productAction";

import { createInvoice } from "./invoice-duck/InvoiceActions";
import { getDocumentById } from "../documents/document-duck/documentAction";

import { DocumentForm } from "../documents/DocumentForm";
import Spinner from "../../components/common/Spinner";
import { DOCUMENT_TYPE } from "../../utils/util";

export class BecomeInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      document: null,

      products: null,
      errors: {}
    };
    this.documentCallBack = this.documentCallBack.bind(this);
  }

  componentDidMount() {
    const {
      match: { params },
      getDocumentById
    } = this.props;
    getDocumentById(params.id);

    if (this.props.product.products) {
      this.setState({ products: this.props.product.products });
    } else {
      this.props.getAllProducts();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.product) {
      this.setState({ products: nextProps.product.products });
    }

    if (nextProps.document.document) {
      const doc = nextProps.document.document;

      this.setState({ document: doc });
    }
  }

  documentCallBack = documentData => {
    let doc = documentData;
    doc.id = this.state.document._id;
    doc.type = DOCUMENT_TYPE.INVOICE;

    this.props.createInvoice(doc, this.props.history, false);
  };

  render() {
    const { document } = this.state;
    return (
      <div className="bid">
        <div className="container">
          {this.state.customers !== null &&
          document !== null &&
          this.state.products !== null ? (
            <DocumentForm
              title="הזמנה לביצוע - מהצעת מחיר"
              auth={this.props.auth}
              products={this.state.products}
              customers={[]}
              documentCallBack={this.documentCallBack}
              withVat={document.vat ? true : false}
              discount={document.discount}
              note={document.note}
              selectedCustomer={document.customer.customerData}
              items={document.items}
              editMode={true}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    );
  }
}

BecomeInvoice.propTypes = {
  auth: PropTypes.object,
  products: PropTypes.object,
  createInvoice: PropTypes.func,
  getAllProducts: PropTypes.func,
  getDocumentById: PropTypes.func
};

const mapStateToProps = state => ({
  auth: state.auth,
  document: state.document,
  product: state.product
});

export default connect(
  mapStateToProps,
  { getAllProducts, createInvoice, getDocumentById }
)(BecomeInvoice);
