import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { createProduct, getProductById } from "./products-duck/productAction";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import TextAreaFieldGroup from "../../components/common/TextAreaFieldGroup";
import SelectListGroup from "../../components/common/SelectListGroup";
import isEmpty from "../../validation/is-empty";
import { PRODUCT_TYPE } from "../../utils/util";

import "./css/products.css";

class EditProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      description: "",
      barcode: "",
      price: "",
      costPrice: "",
      type: PRODUCT_TYPE.UNIT,

      errors: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const {
      match: { params },
      getProductById
    } = this.props;
    getProductById(params.id);
  }

  onSubmit(e) {
    e.preventDefault();

    const productData = {
      id: this.state.id,
      name: this.state.name,
      description: this.state.description,
      barcode: this.state.barcode,
      price: this.state.price,
      costPrice: this.state.costPrice,
      type: this.state.type
    };
    this.props.createProduct(productData, this.props.history, false);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      if (nextProps.errors.noproduct) {
        console.log(nextProps.errors.noproduct);
        this.props.history.push("/not-found");
      }
    }

    if (nextProps.product.product) {
      const product = nextProps.product.product;
      // if product field doesnt exist, make empty string
      product.name = !isEmpty(product.name) ? product.name : "";
      product.price = !isEmpty(product.price) ? product.price.toString() : "";
      product.costPrice = !isEmpty(product.costPrice)
        ? product.costPrice.toString()
        : "";
      product.description = !isEmpty(product.description)
        ? product.description
        : "";
      product.barcode = !isEmpty(product.barcode) ? product.barcode : "";
      product.type = !isEmpty(product.type) ? product.type : "";
      product.id = !isEmpty(product._id) ? product._id : "";

      this.setState({
        id: product.id,
        name: product.name,
        barcode: product.barcode,
        description: product.description,
        price: product.price,
        type: product.type,
        costPrice: product.costPrice
      });
    }
  }

  render() {
    const { errors } = this.state;
    const typeOption = [
      { label: "לפי יחדה", value: PRODUCT_TYPE.UNIT },
      { label: "לפי אורך", value: PRODUCT_TYPE.LENGTH }
    ];

    return (
      <div className="create-product">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">עדכון מוצר קיים</h1>
              <p className="lead text-center">טופס עדכון מוצר קיים במערכת</p>
              <small className="d-block pb-3">שדות חובה = *</small>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="שם המוצר *"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                />

                <TextAreaFieldGroup
                  placeholder="אפיון המוצר"
                  name="description"
                  value={this.state.description}
                  onChange={this.onChange}
                  error={errors.description}
                />

                <TextFieldGroup
                  placeholder="בארקוד *"
                  name="barcode"
                  value={this.state.barcode}
                  onChange={this.onChange}
                  error={errors.barcode}
                />
                <TextFieldGroup
                  placeholder="מחיר *"
                  name="price"
                  value={this.state.price}
                  onChange={this.onChange}
                  type="number"
                  error={errors.price}
                />
                <TextFieldGroup
                  placeholder="מחיר עלות *"
                  name="costPrice"
                  value={this.state.costPrice}
                  onChange={this.onChange}
                  type="number"
                  error={errors.costPrice}
                />
                <SelectListGroup
                  name="type"
                  value={this.state.type}
                  onChange={this.onChange}
                  options={typeOption}
                />

                <input
                  type="submit"
                  value="בצע"
                  className="btn btn-info btn-block mt-4"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditProduct.propTypes = {
  createProduct: PropTypes.func.isRequired,
  getProductById: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  product: state.product,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { createProduct, getProductById }
)(withRouter(EditProduct));
