import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import InputGroup from '../../components/common/InputGroup';
import Spinner from '../../components/common/Spinner';
import { DOCUMENT_TYPE, INVOICE_STATUS } from '../../utils/util';
import * as COLOR from '../../utils/colors';
import { downloadPDF } from './document-duck/documentAction';
import moment from 'moment';

// React Table
import ReactTable from 'react-table';

// React table style ".css"
import 'react-table/react-table.css';

import './css/documents.css';

class DocumentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      documents: [],
      errors: {},
      page: 0,
      maxCalledPage: 0,

      pages: null,
    };

    this.onChange = this.onChange.bind(this);
    this.downloadPDFDocument = this.downloadPDFDocument.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state, instance) {
    this.props.getData(state.pageSize, state.page);
  }

  fillTableWithSearchFilter(search) {
    let updatedList = this.props.document.documents;
    updatedList = updatedList.filter(function (item) {
      return (
        item.type.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.sequence.toString().search(search.toLowerCase()) !== -1 ||
        item.totalPrice.toString().search(search.toLowerCase()) !== -1 ||
        (item.customer
          ? item.customer.fullName
              .toLowerCase()
              .search(search.toLowerCase()) !== -1
          : false) ||
        (item.customer
          ? item.customer.phoneNumber
              .toString()
              .search(search.toLowerCase()) !== -1
          : false) ||
        (item.note
          ? item.note.toLowerCase().search(search.toLowerCase()) !== -1
          : false) ||
        new Date(item.createdAt)
          .toLocaleDateString()
          .toString()
          .toLowerCase()
          .search(search.toLowerCase()) !== -1
      );
    });
    this.setState({ documents: updatedList });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'search') {
      const search = e.target.value;
      if (search === '') {
        this.setState({ documents: this.props.document.documents });
        return;
      }
      this.fillTableWithSearchFilter(search);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.document) {
      let newDocs = nextProps.document.documents;
      if (
        this.state.documents &&
        this.state.documents.length > 0 &&
        !nextProps.document.loading
      ) {
        newDocs = this.state.documents.concat(newDocs);
      }
      if (!nextProps.document.loading)
        this.setState({
          documents: newDocs,
        });
    }
  }

  componentDidMount() {
    this.props.getData(20, 0);
  }

  downloadPDFDocument(e, document) {
    e.preventDefault();
    let fileName = document.type.substring(0, 2) + '-' + document.sequence;
    this.props.downloadPDF(document._id, fileName);
  }

  render() {
    const { loading } = this.props.document;
    const { documents, errors } = this.state;

    let content;

    const columns = [
      {
        Header: 'סוג מסמך',
        accessor: 'type',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              {row.row.type === DOCUMENT_TYPE.INVOICE
                ? 'הזמנה'
                : row.row.type === DOCUMENT_TYPE.RECEIPT
                ? 'קבלה'
                : row.row.type === DOCUMENT_TYPE.BID
                ? 'הצעת מחיר'
                : row.row.type === DOCUMENT_TYPE.CREDIT
                ? 'זיכוי'
                : null}
            </div>
          );
        },
      },
      {
        Header: 'מספר מסמך',
        accessor: 'sequence',
        maxWidth: 100,
      },
      {
        Header: 'שם לקוח',
        accessor: 'customer.fullName',
      },
      {
        Header: 'מס׳ טלפון',
        accessor: 'customer.phoneNumber',
        maxWidth: 120,
      },
      /*{
        accessor: 'status',
        maxWidth: 110,
        Cell: (row) => {
          return (
            <span>
              {row.value
                ? row.value === INVOICE_STATUS.FULL_PAID
                  ? 'שולם'
                  : row.value === INVOICE_STATUS.PARTIALLY_PAID
                  ? 'שולם חלקי'
                  : ''
                : ''}
            </span>
          );
        },
      },*/

      {
        Header: 'סכ׳׳ה',
        accessor: 'totalPrice',
        maxWidth: 130,
        Cell: (row) => {
          return <span>₪ {row.value.toFixed(2)}</span>;
        },
        getProps: (state, rowInfo) => {
          return {
            style: {
              color: rowInfo
                ? rowInfo.row.type === DOCUMENT_TYPE.INVOICE
                  ? COLOR.RED
                  : rowInfo.row.type === DOCUMENT_TYPE.RECEIPT
                  ? COLOR.GREEN
                  : rowInfo.row.type === DOCUMENT_TYPE.BID
                  ? COLOR.YELLOW
                  : rowInfo.row.type === DOCUMENT_TYPE.CREDIT
                  ? COLOR.GRAY
                  : null
                : null,
            },
          };
        },
      },
      {
        Header: 'תאריך',
        accessor: 'createdAt',
        Cell: (row) => {
          return <span>{moment(row.value).format('DD/MM/YYYY HH:mm')}</span>;
        },
        maxWidth: 160,
      },
      {
        Header: ' ',
        id: '_id',
        accessor: '_id',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              <Link to={`/document/${row.row._id}`}>צפה</Link>
              {row.row.type !== DOCUMENT_TYPE.RECEIPT ? ' | ' : ''}
              {row.row.type !== DOCUMENT_TYPE.RECEIPT ? (
                <Link
                  to="#"
                  onClick={(e) => this.downloadPDFDocument(e, row.row)}
                >
                  הדפס
                </Link>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
    ];

    if (documents === null || loading) content = <Spinner />;
    else if (documents.length > 0) {
      content = (
        <div className="col-md-12">
          <ReactTable
            loading={loading}
            data={documents}
            columns={columns}
            defaultPageSize={20}
            pages={100}
            page={this.state.page}
            //manual
            //onFetchData={this.fetchData}
            onPageChange={(pageIndex) => {
              if (
                pageIndex > this.state.page &&
                pageIndex > this.state.maxCalledPage
              ) {
                this.setState({ maxCalledPage: pageIndex });
                this.props.getData(20, pageIndex);
              }
              this.setState({ page: pageIndex });
            }}
            getTrProps={(state, rowInfo) => {
              return {
                style: {
                  background: rowInfo
                    ? rowInfo.row.type === DOCUMENT_TYPE.INVOICE
                      ? COLOR.RED_BACKGROUND
                      : rowInfo.row.type === DOCUMENT_TYPE.RECEIPT
                      ? COLOR.GREEN_BACKGROUND
                      : rowInfo.row.type === DOCUMENT_TYPE.BID
                      ? COLOR.YELLOW_BACKGROUND
                      : rowInfo.row.type === DOCUMENT_TYPE.CREDIT
                      ? COLOR.GRAY_BACKGROUND
                      : null
                    : null,
                },
              };
            }}
          />
        </div>
      );
    } else {
      content = <h4>לא נמצא מסמכים...</h4>;
    }

    return (
      <div className="container">
        <div className="row">
          {/*
          <div className="col-md-12 m-auto">
            <form onSubmit={this.onSubmit}>
              <InputGroup
                placeholder="מחפשים מסמך? הקלידו כאן ונמצא לכם"
                name="search"
                value={this.state.search}
                onChange={this.onChange}
                error={errors.search}
                icon="fas fa-search"
              />
            </form>
          </div>
          */}
          {content}
        </div>
      </div>
    );
  }
}

DocumentsTable.propTypes = {
  getData: PropTypes.func,
  downloadPDF: PropTypes.func.isRequired,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  document: state.document,
  auth: state.auth,
});

export default connect(mapStateToProps, { downloadPDF })(DocumentsTable);
