import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createPdfInvoice } from "./order-duck/orderActions";

class Invoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {}
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
  }

  componentDidMount() {
    const {
      match: { params },
      createPdfInvoice
    } = this.props;
    createPdfInvoice(params.id);
  }
  componentWillReceiveProps(nextProps) {}

  render() {
    //const { value } = this.state;

    return (
      <div className="invoice">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="display-4">Invoice</h1>
              <hr />
              <h2>kjasd sjdkbfa sdf</h2>
              <hr />

              <div className="row ">
                <div className="col-md-6">
                  <h4>לקוח</h4>
                  <div className="form-group" />
                </div>
                <div className="col-md-6">
                  <h4>מוצרים</h4>
                  <div />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <hr />
                  <div className="row" />
                </div>
              </div>

              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="submit"
                      value="בצע"
                      className="btn btn-info btn-block mt-4"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Invoice.propTypes = {
  createPdfInvoice: PropTypes.func.isRequired,
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { createPdfInvoice }
)(Invoice);
