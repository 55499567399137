import React from "react";
import PropTypes from "prop-types";

import ItemsTable from "./ItemsTable";
import PaymentTable from "./PaymentTable";
import zeroPad from "../../utils/util";
import { DOCUMENT_TYPE } from "../../utils/util";

const InvoiceView = ({ document, isAdmin }) => {
  const date = new Date(document.createdAt);
  const isReceipt = document.type === DOCUMENT_TYPE.RECEIPT;
  return (
    <div className="row">
      {document ? (
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-sm-4">
                  <dl className="row p-0 m-0">
                    <dt className="col-sm-5">תאריך</dt>
                    <dd className="col-sm-5">{date.toLocaleDateString()}</dd>

                    <dt className="col-sm-5">שעה</dt>
                    <dd className="col-sm-5">
                      {zeroPad(date.getHours(), 2)}:
                      {zeroPad(date.getMinutes(), 2)}
                    </dd>
                  </dl>
                </div>
                <div className="col-sm-4">
                  <h2 className="text-center">
                    {document.type === DOCUMENT_TYPE.INVOICE
                      ? "IV"
                      : document.type === DOCUMENT_TYPE.BID
                      ? "BD"
                      : document.type === DOCUMENT_TYPE.RECEIPT
                      ? "RE"
                      : document.type === DOCUMENT_TYPE.CREDIT
                      ? "CR"
                      : ""}
                    {document.sequence}
                  </h2>
                </div>
                <div className="col-sm-4 text-left">
                  {!isReceipt ? (
                    <label>מספר פריטים: {document.items.length}</label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="row p-5">
                <div className="col-md-12">
                  {isReceipt ? (
                    <PaymentTable payment={document.payment} />
                  ) : (
                    <ItemsTable
                      items={document.items}
                      isAdmin={isAdmin}
                      totalPrice={document.totalPrice}
                    />
                  )}
                </div>
              </div>
              {!document.note ? (
                ""
              ) : (
                <div className="p-5">
                  <h4>הערות:</h4>
                  <hr />
                  <div className="col-md-12">{document.note}</div>
                  <hr />
                </div>
              )}

              <div className="d-flex flex-row-reverse bg-dark text-white p-4">
                <div className="py-3 px-5 text-right">
                  <div className="mb-2">
                    סה׳׳כ {isReceipt ? "תשלום" : "מחיר"}
                  </div>
                  <div className="h2 font-weight-light">
                    {document.totalPrice.toFixed(2)} ₪
                  </div>
                </div>

                {document.vat ? (
                  <div className="py-3 px-5 text-right">
                    <div className="mb-2">סה׳׳כ מחיר לפמי מע׳׳מ</div>
                    <div className="h2 font-weight-light">
                      {document.totalPriceBeforeVat.toFixed(2)} ₪
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {document.discount ? (
                  <div className="py-3 px-5 text-right">
                    <div className="mb-2">הנחה</div>
                    <div className="h2 font-weight-light">
                      {document.discount}%
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {document.discount ? (
                  <div className="py-3 px-5 text-right">
                    <div className="mb-2">סה׳׳ה מחיר לפני הנחה</div>
                    <div className="h2 font-weight-light">
                      {document.totalPriceBeforeDiscount.toFixed(2)} ₪
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-md-12"> </div>
      )}
    </div>
  );
};
InvoiceView.propTypes = {
  document: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool
};

InvoiceView.defaultProps = {};

export default InvoiceView;
