import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from './components/common/PrivateRoute';

import Dashboard from './containers/dashboard/Dashboard';
import Register from './containers/auth/Register';
import NotFound from './components/not-found/NotFound';

import CreateCustomer from './containers/customers/CreateCustomer';
import EditCustomer from './containers/customers/EditCustomer';
import CustomersTable from './containers/customers/CustomersTable';
import CustomerProfile from './containers/customers/CustomerProfile';

import CreateProduct from './containers/products/CreateProduct';
import ProductsTable from './containers/products/ProductsTable';
import EditProduct from './containers/products/EditProduct';

import Order from './containers/order/Order';
import Invoice from './containers/order/Invoice';

import CreateInvoice from './containers/Invoice/CreateInvoice-2';
import BecomeInvoice from './containers/Invoice/BecomeInvoice';
import CreateBid from './containers/bid/CreateBid';
import EditBid from './containers/bid/EditBid';
import CreateCredit from './containers/credit/CreateCredit';
import BecomeCredit from './containers/credit/BecomeCredit';
import CreateReceipt from './containers/receipt/CreateReceipt';
import SearchChecks from './containers/receipt/SearchChecks';
import Documents from './containers/documents/Documents';
import DocumnetView from './containers/documents/DocumnetView';

export default function () {
  return (
    <div className="container">
      <Switch>
        <PrivateRoute exact path="/register" component={Register} />
      </Switch>

      <Switch>
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
      </Switch>

      <Switch>
        <PrivateRoute exact path="/add-customer" component={CreateCustomer} />
      </Switch>

      <Switch>
        <PrivateRoute exact path="/customer/:id" component={EditCustomer} />
      </Switch>

      <Switch>
        <PrivateRoute exact path="/profile/:id" component={CustomerProfile} />
      </Switch>

      <Switch>
        <PrivateRoute exact path="/customers" component={CustomersTable} />
      </Switch>

      <Switch>
        <PrivateRoute exact path="/add-product" component={CreateProduct} />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/products" component={ProductsTable} />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/product/:id" component={EditProduct} />
      </Switch>

      <Switch>
        <PrivateRoute exact path="/order" component={Order} />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/create-invoice/" component={CreateInvoice} />
      </Switch>
      <Switch>
        <PrivateRoute
          exact
          path="/become-invoice/:id"
          component={BecomeInvoice}
        />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/create-bid/" component={CreateBid} />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/edit-bid/:id" component={EditBid} />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/create-receipt/" component={CreateReceipt} />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/search-check/" component={SearchChecks} />
      </Switch>
      <Switch>
        <PrivateRoute
          exact
          path="/create-receipt/:id/:customer"
          component={CreateReceipt}
        />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/create-credit/" component={CreateCredit} />
      </Switch>
      <Switch>
        <PrivateRoute
          exact
          path="/become-credit/:id"
          component={BecomeCredit}
        />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/invoice/:id" component={Invoice} />
      </Switch>

      <Switch>
        <PrivateRoute exact path="/documents/" component={Documents} />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/document/:id" component={DocumnetView} />
      </Switch>

      <Switch>
        <Route path="/not-found" component={NotFound} />
      </Switch>
    </div>
  );
}
