import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { createProduct } from "./products-duck/productAction";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import TextAreaFieldGroup from "../../components/common/TextAreaFieldGroup";
import SelectListGroup from "../../components/common/SelectListGroup";
import { PRODUCT_TYPE } from "../../utils/util";

import "./css/products.css";

class CreateProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      barcode: "",
      price: "",
      costPrice: "",
      type: PRODUCT_TYPE.UNIT,

      errors: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const productData = {
      name: this.state.name,
      description: this.state.description,
      barcode: this.state.barcode,
      price: this.state.price,
      costPrice: this.state.costPrice,
      type: this.state.type
    };
    this.props.createProduct(productData, this.props.history, true);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  render() {
    const { errors } = this.state;
    const typeOption = [
      { label: "לפי יחדה", value: PRODUCT_TYPE.UNIT },
      { label: "לפי אורך", value: PRODUCT_TYPE.LENGTH }
    ];

    return (
      <div className="create-product">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">הספת מוצר חדש</h1>
              <p className="lead text-center">טופס הקמת מוצר חדש במערכת</p>
              <small className="d-block pb-3">שדות חובה = *</small>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="שם המוצר *"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  error={errors.name}
                />

                <TextAreaFieldGroup
                  placeholder="אפיון המוצר"
                  name="description"
                  value={this.state.description}
                  onChange={this.onChange}
                  error={errors.description}
                />

                <TextFieldGroup
                  placeholder="בארקוד *"
                  name="barcode"
                  value={this.state.barcode}
                  onChange={this.onChange}
                  error={errors.barcode}
                />
                <TextFieldGroup
                  placeholder="מחיר *"
                  name="price"
                  value={this.state.price}
                  onChange={this.onChange}
                  type="number"
                  step="0.1"
                  error={errors.price}
                />
                <TextFieldGroup
                  placeholder="מחיר עלות *"
                  name="costPrice"
                  value={this.state.costPrice}
                  onChange={this.onChange}
                  type="number"
                  step="0.1"
                  error={errors.costPrice}
                />
                <SelectListGroup
                  name="type"
                  value={this.state.type}
                  onChange={this.onChange}
                  options={typeOption}
                />

                <input
                  type="submit"
                  value="בצע"
                  className="btn btn-info btn-block mt-4"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateProduct.propTypes = {
  product: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  product: state.product,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { createProduct }
)(withRouter(CreateProduct));
