import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Spinner from "../../components/common/Spinner";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import { DOCUMENT_TYPE } from "../../utils/util";
import { searchChecks } from "../documents/document-duck/documentAction";

// React Table
import ReactTable from "react-table";

// React table style ".css"
import "react-table/react-table.css";

export class SearchChecks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkNumber: "",
      accountNumber: "",

      documents: []
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    this.props.searchChecks(this.state.checkNumber, this.state.accountNumber);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.document) {
      this.setState({ documents: nextProps.document.documents });
    }
  }
  componentDidMount() {}

  render() {
    const { loading } = this.props.document;
    const { documents } = this.state;

    let content;
    const columns = [
      {
        Header: "סוג מסמך",
        accessor: "type",
        maxWidth: 100,
        Cell: row => {
          return (
            <div>
              {row.row.type === DOCUMENT_TYPE.INVOICE
                ? "הזמנה"
                : row.row.type === DOCUMENT_TYPE.RECEIPT
                ? "קבלה"
                : row.row.type === DOCUMENT_TYPE.BID
                ? "הצעת מחיר"
                : row.row.type === DOCUMENT_TYPE.CREDIT
                ? "זיכוי"
                : null}
            </div>
          );
        }
      },
      {
        Header: "מספר מסמך",
        accessor: "sequence",
        maxWidth: 100
      },
      {
        Header: "שם לקוח",
        accessor: "customer.fullName"
      },
      {
        Header: "מס׳ טלפון",
        accessor: "customer.phoneNumber",
        maxWidth: 120
      },
      {
        Header: "סכ׳׳ה",
        accessor: "totalPrice",
        maxWidth: 130,
        Cell: row => {
          return <span>₪ {row.value.toFixed(2)}</span>;
        }
      },
      {
        Header: " ",
        id: "_id",
        accessor: "_id",
        maxWidth: 100,
        Cell: row => {
          return (
            <div>
              <Link to={`/document/${row.row._id}`}>צפה</Link>
            </div>
          );
        }
      }
    ];
    if (loading) content = <Spinner />;
    else if (documents.length === 0)
      content = (
        <div className="row mt-4">
          <div className="col-sm-12 mt-4">
            <div className="text-center mt-4 p-4">
              <h4>לא נמצא מסמכים...</h4>
            </div>
          </div>
        </div>
      );
    else
      content = (
        <div className="row">
          <div className="col-md-12">
            <ReactTable
              loading={loading}
              data={documents}
              columns={columns}
              pageSize={20}
            />
          </div>
        </div>
      );

    return (
      <div className="container">
        <form onSubmit={this.onSubmit}>
          <div className="row mt-5">
            <div className="col-md-5">
              <TextFieldGroup
                placeholder="הקלד מספר צ׳יק"
                name="checkNumber"
                value={this.state.checkNumber}
                onChange={this.onChange}
              />
            </div>
            <div className="col-md-5">
              <TextFieldGroup
                placeholder="הקלד מספר חשבון בנק"
                name="accountNumber"
                value={this.state.accountNumber}
                onChange={this.onChange}
              />
            </div>
            <div className="col-md-2">
              <input
                type="submit"
                value="חפש"
                className="btn btn-info btn-block mt-1"
              />
            </div>
          </div>
        </form>

        {content}
      </div>
    );
  }
}

SearchChecks.propTypes = {
  auth: PropTypes.object,
  searchChecks: PropTypes.func
};

const mapStateToProps = state => ({
  auth: state.auth,
  document: state.document
});

export default connect(
  mapStateToProps,
  { searchChecks }
)(SearchChecks);
