import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Chart, Axis, Tooltip, Geom } from "bizcharts";

import axios from "axios";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      loading: true
    };
  }
  componentDidMount() {
    axios.get("/api/dashboard").then(res => {
      console.log(res.data);
      this.setState({ data: res.data, loading: false });
    });
  }
  render() {
    const { isAdmin } = this.props.auth.user;
    const { loading, data } = this.state;
    const monthly = [];

    data &&
      Object.keys(data.salesPerMonth).forEach(month => {
        console.log(month);
        monthly.push({
          month: month,
          sales: parseInt(data.salesPerMonth[month].value)
        });
      });
    console.log(monthly);
    const cols = {
      sales: {
        alias: "מכירות"
      },
      month: {
        alias: "חודש"
      }
    };

    return (
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center">Dashboard</h1>
              <hr />
              {loading && !isAdmin ? (
                <h1 dir="ltr" className="text-left">
                  Comming Soon...
                </h1>
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="text-center">מכירות חודשי</h4>
                    <Chart height={400} data={monthly} scale={cols} forceFit>
                      <Axis name="month" title="חודש" />
                      <Axis name="sales" />
                      <Tooltip
                        crosshairs={{
                          type: "y"
                        }}
                      />
                      <Geom type="interval" position="month*sales" />
                    </Chart>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(Dashboard);
