import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getCustomerById } from './customers-duck/customerActions';
import { getCustomerDocuments } from '../documents/document-duck/documentAction';

import ProfileDocumentsTable from '../documents/ProfileDocumentsTable';
import CustomerView from './CustomerView';

import './css/customer-profile.css';

class CustomerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      match: { params },
      getCustomerById,
    } = this.props;

    getCustomerById(params.id);
  }

  getData = () => {
    const {
      match: { params },
      getCustomerDocuments,
    } = this.props;
    getCustomerDocuments(params.id);
  };

  goToEditPage;

  render() {
    const {
      customer: { customer },
    } = this.props;
    return (
      <div className={'customer-profile-wrapper'}>
        {/*Top Section*/}
        {customer ? (
          <div className="col-md-12 edit-profile">
            <CustomerView customer={customer} />
            <Link to={`/customer/${customer._id}`}>
              <span className="text-center">
                <i className="far fa-edit" />
                <br />
                ערוך לקוח
              </span>
            </Link>
          </div>
        ) : (
          ''
        )}

        <hr />
        {/* profile */}
        <div className="row">
          {/**  <div className="col-sm-2">
            {' '}
            <WidgetWrapper className={'user-info'}>
              <ul>
                <li>
                  {customer && customer.firstName}{' '}
                  {customer && customer.lastName}
                </li>
                {customer && customer.idNumber ? (
                  <li>{customer.idNumber}</li>
                ) : (
                  ''
                )}

                {customer && customer.birthDate ? (
                  <li>{new Date(customer.birthDate).toLocaleDateString()}</li>
                ) : (
                  ''
                )}

                <li>{customer && customer.phoneNumber}</li>
                <li>
                  <span dir="ltr">
                    {customer && customer.account.total.toFixed(2)} ₪
                  </span>
                </li>
                <li>
                  מס׳ מסמכים:{' '}
                  {this.props.documents.documents &&
                    this.props.documents.documents.length}
                </li>
              </ul>
            </WidgetWrapper>
          </div>
          */}
          <div className="col-sm-12 transactions">
            <ProfileDocumentsTable getData={this.getData} />
          </div>
        </div>
        <div className="main-wrapper" />
      </div>
    );
  }
}

CustomerProfile.propTypes = {
  getCustomerById: PropTypes.func,
  getCustomerDocuments: PropTypes.func,
  auth: PropTypes.object,
  customer: PropTypes.object,
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  documents: state.document,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getCustomerById,
  getCustomerDocuments,
})(CustomerProfile);
