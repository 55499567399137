import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../containers/auth/auth-duck/authActions";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

class Navbar2 extends Component {
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;

    const authLinks = (
      <Nav className="mr-auto">
        <NavDropdown title="לקוחות" id="basic-nav-customer">
          <NavDropdown.Item href="/add-customer"> הוספת לקוח</NavDropdown.Item>
          <NavDropdown.Item href="/customers">לקוחות</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="מוצרים" id="basic-nav-product">
          <NavDropdown.Item href="/add-product"> הוספת מוצר</NavDropdown.Item>
          <NavDropdown.Item href="/products">מוצרים</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link href="/documents">מסמכים</Nav.Link>
        <Nav.Link href="/create-invoice">הזמנה לביצוע</Nav.Link>
        <Nav.Link href="/create-bid">הצעת מחיר</Nav.Link>
        <Nav.Link href="/create-receipt">קבלה</Nav.Link>
        <Nav.Link href="/create-credit">זיכוי</Nav.Link>
        <Nav.Link href="/search-check">חפש צ׳יק</Nav.Link>

        {user.isAdmin ? <Nav.Link href="/register">הרשמה</Nav.Link> : ""}
        <Nav.Link href="#" onClick={this.onLogoutClick.bind(this)}>
          <img
            className="rounded-circle"
            src={user.avatar}
            alt={user.name}
            style={{ width: "25px", marginRight: "5px" }}
            title="You must have a Gravatar connected to your email to display an image"
          />
          {" " + user.name + " - "}
          יצאה
        </Nav.Link>
      </Nav>
    );

    const guestLinks = (
      <Nav className="mr-auto navbar-nav ">
        <Nav.Link href="/register">הרשמה</Nav.Link>
        <Nav.Link href="/login">כניסה</Nav.Link>
      </Nav>
    );

    return (
      <div className="row bg-dark nv">
        <div className="container">
          <Navbar variant="dark" expand="lg">
            <Navbar.Brand href="/">
              {" "}
              <img
                src="/img/logo-256-w.png"
                className="nav-logo"
                alt="Connect"
              />{" "}
              Connect
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="" id="basic-navbar-nav">
              {isAuthenticated ? authLinks : guestLinks}
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}

Navbar2.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(Navbar2);
