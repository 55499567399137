import React from "react";
import PropTypes from "prop-types";

import { PRODUCT_TYPE } from "../../utils/util";

const ItemsTable = ({ items, totalPrice, isAdmin }) => {
  /*const titles = ["תאור", "מק׳׳ט", "אורך", "כמות", "מחיר יח׳", "סה׳׳כ"];
  const titles_admin = [
    "תאור",
    "מק׳׳ט",
    "אורך",
    "כמות",
    "מחיר יח׳",
    "רוח",
    "סה׳׳כ"
  ];*/

  const totalCostPrice = items.reduce((sum, item) => {
    return sum + item.costPrice * item.quantity * item.length;
  }, 0);

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th className="border-0 text-uppercase small font-weight-bold ">
              תאור
            </th>
            <th className="border-0 text-uppercase small font-weight-bold ">
              מק׳׳ט
            </th>
            <th className="border-0 text-uppercase small font-weight-bold ">
              אורך
            </th>
            <th className="border-0 text-uppercase small font-weight-bold ">
              כמות
            </th>
            <th className="border-0 text-uppercase small font-weight-bold ">
              מחיר יח׳
            </th>
            {isAdmin ? (
              <th className="border-0 text-uppercase small font-weight-bold ">
                רוח
              </th>
            ) : (
              ""
            )}
            <th className="border-0 text-uppercase small font-weight-bold text-left">
              סה׳׳כ
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.product.barcode}</td>
              <td>
                {item.type === PRODUCT_TYPE.LENGTH ? item.length + " מטר" : ""}
              </td>
              <td>{item.quantity}</td>
              <td>{item.price.toFixed(2)}</td>

              {isAdmin ? (
                <td className=" font-weight-bold ">
                  {(
                    item.price * item.quantity * item.length -
                    item.costPrice * item.quantity * item.length
                  ).toFixed(2)}{" "}
                  ₪
                </td>
              ) : (
                ""
              )}

              <td className="text-left">
                {(item.price * item.quantity * item.length).toFixed(2)} ₪
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isAdmin ? (
        <div className="row">
          <div className="col-sm-12 text-left">
            <span>סה׳׳כ מחיר עלות : {totalCostPrice.toFixed(2)} ₪</span>
            <br />

            <span>
              סה׳׳כ רוח : {(totalPrice - totalCostPrice).toFixed(2)} ₪
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

ItemsTable.propTypes = {
  items: PropTypes.array.isRequired,
  totalPrice: PropTypes.number,
  isAdmin: PropTypes.bool
};

ItemsTable.defaultProps = {
  isAdmin: false,
  totalPrice: 0
};

export default ItemsTable;
