import {
  UPDATE_CUSTOMER,
  GET_CUSTOMER,
  GET_CUSTOMERS,
  CUSTOMER_LOADING,
  CLEAR_CURRENT_CUSTOMER
} from "../../../app-duck/actions/types";

const initialState = {
  customer: null,
  customers: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
        loading: false
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
        loading: false
      };
    case CLEAR_CURRENT_CUSTOMER:
      return {
        ...state,
        customer: null
      };
    case UPDATE_CUSTOMER: {
      return {
        ...state,
        customer: action.payload,
        loading: false
      };
    }
    default:
      return state;
  }
}
