import {
  GET_DOCUMENT,
  GET_DOCUMENTS,
  DOCUMENT_LOADING,
  CLEAR_CURRENT_DOCUMENT,
  DOWNLOAD_DOCUMENT
} from "../../../app-duck/actions/types";

const initialState = {
  document: null,
  documents: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DOCUMENT_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_DOCUMENT:
      return {
        ...state,
        document: action.payload,
        loading: false
      };
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
        loading: false
      };
    case CLEAR_CURRENT_DOCUMENT:
      return {
        ...state,
        document: null
      };
    case DOWNLOAD_DOCUMENT:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
