import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getAllProducts } from "../products/products-duck/productAction";
import { getCustomers } from "../customers/customers-duck/customerActions";
import { createCredit } from "../Invoice/invoice-duck/InvoiceActions";

import { DocumentForm } from "../documents/DocumentForm";
import Spinner from "../../components/common/Spinner";
import { DOCUMENT_TYPE } from "../../utils/util";

export class CreateCredit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: null,
      customers: null,
      errors: {}
    };
    this.documentCallBack = this.documentCallBack.bind(this);
  }

  componentDidMount() {
    if (this.props.product.products) {
      this.setState({ products: this.props.product.products });
    } else {
      this.props.getAllProducts();
    }

    if (this.props.customer.customers) {
      this.setState({ customers: this.props.customer.customers });
    } else {
      this.props.getCustomers();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.product) {
      this.setState({ products: nextProps.product.products });
    }
    if (nextProps.customer) {
      this.setState({ customers: nextProps.customer.customers });
    }
  }

  documentCallBack = documentData => {
    let doc = documentData;
    doc.type = DOCUMENT_TYPE.CREDIT;

    this.props.createCredit(doc, this.props.history);
  };

  render() {
    return (
      <div className="bid">
        <div className="container">
          {this.state.customers !== null && this.state.products !== null ? (
            <DocumentForm
              title="זיכוי"
              auth={this.props.auth}
              products={this.state.products}
              customers={this.state.customers}
              documentCallBack={this.documentCallBack}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    );
  }
}

CreateCredit.propTypes = {
  auth: PropTypes.object,
  products: PropTypes.object,
  customer: PropTypes.object,
  createCredit: PropTypes.func,
  getAllProducts: PropTypes.func,
  getCustomers: PropTypes.func
};

const mapStateToProps = state => ({
  auth: state.auth,
  product: state.product,
  customer: state.customer
});

export default connect(
  mapStateToProps,
  { getAllProducts, getCustomers, createCredit }
)(CreateCredit);
