import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Spinner from '../../components/common/Spinner';
import InputGroup from "../../components/common/InputGroup"; 
import { DOCUMENT_TYPE } from '../../utils/util';
import * as COLOR from '../../utils/colors';
import { downloadPDF } from './document-duck/documentAction';
import moment from 'moment';

// React Table
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';

// React table style ".css"
import 'react-table/react-table.css';

import './css/documents.css';
const SelectTable = selectTableHOC(ReactTable);

class ProfileDocumentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      documents: [],
      selectAll: false,
      selection: [],

      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.downloadPDFDocument = this.downloadPDFDocument.bind(this);
    this.downloadPDFAllDocument = this.downloadPDFAllDocument.bind(this);
  }

  static defaultProps = {
    keyField: '_id',
  };

  static propTypes = {
    keyField: PropTypes.string,
  };

  fillTableWithSearchFilter(search) {
    let updatedList = this.props.document.documents;
    updatedList = updatedList.filter(function (item) {
      return (
        item.type.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.sequence.toString().search(search.toLowerCase()) !== -1 ||
        item.totalPrice.toString().search(search.toLowerCase()) !== -1 ||
        (item.customer
          ? item.customer.fullName
              .toLowerCase()
              .search(search.toLowerCase()) !== -1
          : false) ||
        (item.customer
          ? item.customer.phoneNumber
              .toString()
              .search(search.toLowerCase()) !== -1
          : false) ||
        (item.note
          ? item.note.toLowerCase().search(search.toLowerCase()) !== -1
          : false) ||
        new Date(item.createdAt)
          .toLocaleDateString()
          .toString()
          .toLowerCase()
          .search(search.toLowerCase()) !== -1
      );
    });
    this.setState({ documents: updatedList });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'search') {
      const search = e.target.value;
      if (search === '') {
        this.setState({ documents: this.props.document.documents });
        return;
      }
      this.fillTableWithSearchFilter(search);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.document) {
      let newDocs = nextProps.document.documents;
      if (
        this.state.documents &&
        this.state.documents.length > 0 &&
        !nextProps.document.loading
      ) {
        if (this.state.documents[0]._id !== newDocs[0]._id)
          this.state.documents.concat(newDocs);
      }
      if (!nextProps.document.loading)
        this.setState({
          documents: newDocs,
        });
    }
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.props.getData();
  }

  downloadPDFDocument(e, document) {
    e.preventDefault();
    let fileName = document.type.substring(0, 2) + '-' + document.sequence;
    this.props.downloadPDF(document._id, fileName);
  }

  downloadPDFAllDocument(e) {
    e.preventDefault();
    let fileName = Date.now() + '-' + this.state.documents[0].customer.fullName;
    const ids = this.state.selection.map((doc) => doc.split('-')[1]);
    this.props.downloadPDF(ids.join(','), fileName, true);
  }

  toggleSelection = (key, shift, row) => {
    // start off with the existing state
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the state
    this.setState({ selection });
  };

  toggleAll = () => {
    const { keyField } = this.props;
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        console.log(item);
        selection.push(`select-${item._original[keyField]}`);
      });
    }
    this.setState({ selectAll, selection });
  };

  isSelected = (key) => {
    return this.state.selection.includes(`select-${key}`);
  };

  render() {
    const { loading } = this.props.document;
    const { documents, errors, selection } = this.state;

    console.log(this.state.selection);
    let content;

    const columns = [
      {
        Header: 'סוג מסמך',
        accessor: 'type',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              {row.row.type === DOCUMENT_TYPE.INVOICE
                ? 'הזמנה'
                : row.row.type === DOCUMENT_TYPE.RECEIPT
                ? 'קבלה'
                : row.row.type === DOCUMENT_TYPE.BID
                ? 'הצעת מחיר'
                : row.row.type === DOCUMENT_TYPE.CREDIT
                ? 'זיכוי'
                : null}
            </div>
          );
        },
      },
      {
        Header: 'מספר מסמך',
        accessor: 'sequence',
        maxWidth: 100,
      },

      {
        Header: 'סכ׳׳ה',
        accessor: 'totalPrice',
        maxWidth: 130,
        Cell: (row) => {
          return <span>₪ {row.value.toFixed(2)}</span>;
        },
        getProps: (state, rowInfo) => {
          return {
            style: {
              color: rowInfo
                ? rowInfo.row.type === DOCUMENT_TYPE.INVOICE
                  ? COLOR.RED
                  : rowInfo.row.type === DOCUMENT_TYPE.RECEIPT
                  ? COLOR.GREEN
                  : rowInfo.row.type === DOCUMENT_TYPE.BID
                  ? COLOR.YELLOW
                  : rowInfo.row.type === DOCUMENT_TYPE.CREDIT
                  ? COLOR.GRAY
                  : null
                : null,
            },
          };
        },
      },
      {
        Header: 'תאריך',
        accessor: 'createdAt',
        Cell: (row) => {
          return <span>{moment(row.value).format('DD/MM/YYYY HH:mm')}</span>;
        },
        maxWidth: 160,
      },

      {
        Header: 'זכות',
        accessor: 'totalPrice',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              {row.row.type === DOCUMENT_TYPE.INVOICE ? (
                <span> {row.value.toFixed(2)}-</span>
              ) : null}
            </div>
          );
        },
      },

      {
        Header: 'חובה',
        accessor: 'totalPrice',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              {row.row.type === DOCUMENT_TYPE.RECEIPT ||
              row.row.type === DOCUMENT_TYPE.CREDIT ? (
                <span> {row.value.toFixed(2)}</span>
              ) : null}
            </div>
          );
        },
      },

      {
        Header: 'יתרה',
        accessor: 'totalWallet',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              {row.row.totalWallet ? (
                row.value < 0 ? (
                  <span> {(-1 * row.value).toFixed(2)}-</span>
                ) : (
                  <span> {row.value.toFixed(2)}</span>
                )
              ) : null}
            </div>
          );
        },
      },

      {
        Header: ' ',
        id: '_id',
        accessor: '_id',
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div>
              <Link to={`/document/${row.row._id}`}>צפה</Link>
              {row.row.type !== DOCUMENT_TYPE.RECEIPT ? ' | ' : ''}
              {row.row.type !== DOCUMENT_TYPE.RECEIPT ? (
                <Link
                  to="#"
                  onClick={(e) => this.downloadPDFDocument(e, row.row)}
                >
                  הדפס
                </Link>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
    ];

    if (documents === null || loading) content = <Spinner />;
    else if (documents.length > 0) {
      content = (
        <div className="col-md-12">
          {selection && selection.length > 0 ? (
            <div className="sticky side">
              <span
                className="btn btn-info"
                onClick={this.downloadPDFAllDocument}
              >
                הדפס הכל
              </span>
            </div>
          ) : (
            <></>
          )}
          <SelectTable
            selectAll={this.state.selectAll}
            toggleSelection={this.toggleSelection}
            toggleAll={this.toggleAll}
            selectType="checkbox"
            ref={(r) => (this.checkboxTable = r)}
            isSelected={this.isSelected}
            loading={loading}
            data={documents}
            columns={columns}
            defaultPageSize={100}
            page={this.state.page}
            getTrProps={(state, rowInfo) => {
              return {
                style: {
                  background: rowInfo
                    ? rowInfo.row.type === DOCUMENT_TYPE.INVOICE
                      ? COLOR.RED_BACKGROUND
                      : rowInfo.row.type === DOCUMENT_TYPE.RECEIPT
                      ? COLOR.GREEN_BACKGROUND
                      : rowInfo.row.type === DOCUMENT_TYPE.BID
                      ? COLOR.YELLOW_BACKGROUND
                      : rowInfo.row.type === DOCUMENT_TYPE.CREDIT
                      ? COLOR.GRAY_BACKGROUND
                      : null
                    : null,
                },
              };
            }}
          />
        </div>
      );
    } else {
      content = <h4>לא נמצא מסמכים...</h4>;
    }

    return (
      <div className="container">
        <div className="row">
          
          <div className="col-md-12 m-auto">
            <form onSubmit={this.onSubmit}>
              <InputGroup
                placeholder="מחפשים מסמך? הקלידו כאן ונמצא לכם"
                name="search"
                value={this.state.search}
                onChange={this.onChange}
                error={errors.search}
                icon="fas fa-search"
              />
            </form>
          </div>
          
          {content}
        </div>
      </div>
    );
  }
}

ProfileDocumentsTable.propTypes = {
  getData: PropTypes.func,
  downloadPDF: PropTypes.func.isRequired,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  document: state.document,
  auth: state.auth,
});

export default connect(mapStateToProps, { downloadPDF })(ProfileDocumentsTable);
