import {
  ORDER_NOTIFICATION,
  ORDER_CALENDAR,
  ORDER_LOADING,
  CLEAR_ORDER_NOTIFICATION
} from "../../../app-duck/actions/types";

const initialState = {
  notification: null,
  calendar: null,
  loading: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ORDER_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      };
    case ORDER_CALENDAR:
      return {
        ...state,
        calendar: action.payload,
        loading: false
      };
    case ORDER_LOADING:
      return { ...state, loading: true };
    case CLEAR_ORDER_NOTIFICATION:
      return {
        ...state,
        notification: null
      };
    default:
      return state;
  }
}
