import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import MultiSelect from "@kenshooui/react-multi-select";

import { getCustomers } from "../customers/customers-duck/customerActions";
import { getAllProducts } from "../products/products-duck/productAction";
import { createOrder } from "./order-duck/orderActions";

import "./css/order.css";
import "@kenshooui/react-multi-select/dist/style.css";

class Order extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customers: [],
      products: [],
      selectedDay: "",
      selectedCustomer: null,
      value: "",
      product: "",
      selectedProducts: [],
      selectedItems: [],
      suggestions: [],
      totalPrice: 0.0,
      itemsCount: 0,
      errors: {}
    };

    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.clearCustomerCard = this.clearCustomerCard.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const { selectedCustomer } = this.state;
    let _items = [];
    for (let i = 0; i < this.state.selectedProducts.length; i++) {
      let op = this.state.selectedProducts[i].originalPrice;
      let dis = 0;
      let quan = 1;
      let pricePerUnit = op * (1 - (dis === 0 ? 0 : dis / 100));

      _items.push({
        product: this.state.selectedProducts[i],
        name: this.state.selectedProducts[i].name,
        quantity: quan,
        discount: dis,
        originalPrice: op,
        price: pricePerUnit * quan,
        costPrice: this.state.selectedProducts[i].costPrice
      });
    }

    const customer = {
      fullName: selectedCustomer.firstName + " " + selectedCustomer.lastName,
      idNumber: selectedCustomer.idNumber ? selectedCustomer.idNumber : "",
      phoneNumber: selectedCustomer.phoneNumber,
      customerData: selectedCustomer
    };

    const orderData = {
      supplyDate: this.state.selectedDay,
      type: "ORDER",
      totalPrice: this.state.totalPrice,
      totalPriceBeforeDiscount: this.state.totalPrice,
      discount: "0",
      customer: customer,
      items: _items
    };

    this.props.createOrder(orderData, this.props.history);
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }
    const regex = new RegExp(escapedValue, "i");
    return this.state.customers.filter(customer =>
      regex.test(
        customer.firstName +
          " " +
          customer.lastName +
          " " +
          customer.phoneNumber
      )
    );
  }

  setSelectedCustomer(customer) {
    const { selectedCustomer } = this.state;
    if (customer === selectedCustomer) {
    } else {
      this.setState({ selectedCustomer: customer });
    }
  }

  getSuggestionValue(suggestion) {
    this.setSelectedCustomer(suggestion);
    return suggestion.firstName + " " + suggestion.lastName;
  }

  renderSuggestion(suggestion) {
    return <span>{suggestion.firstName + " " + suggestion.lastName}</span>;
  }

  onCustomerChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  onProductChange(selectedItems) {
    const { products } = this.state;
    //this.state.selectedProducts.
    let totalP = 0,
      count = 0;
    for (let i = 0; i < selectedItems.length; i++) {
      var item = products.find(function(o) {
        return o.id === selectedItems[i].id;
      });
      this.state.selectedProducts.push(item);
      totalP += item.price;
      count++;
    }
    this.setState({ selectedItems });
    this.calculateTotalPrice(totalP, count);
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  calculateTotalPrice = (totalPrice, count) => {
    this.setState({ itemsCount: count });
    this.setState({ totalPrice });
  };

  componentDidMount() {
    if (!this.props.location.state) this.props.history.push("not-found");
    else this.setState({ selectedDay: this.props.location.state.selectedDay });

    this.props.getCustomers();
    this.props.getAllProducts();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.customer) {
      this.setState({ customers: nextProps.customer.customers });
    }

    if (nextProps.product && nextProps.product.products !== null) {
      let pr = nextProps.product.products;
      for (let i = 0; i < pr.length; i++) {
        pr[i] = {
          label: pr[i].name + " - " + pr[i].price + "₪",
          id: pr[i]._id,
          ...pr[i]
        };
      }
      this.setState({ products: pr });
    }
  }

  clearCustomerCard(e) {
    e.preventDefault();

    //if(!this.state.selectedCustomer){
    this.setState({ selectedCustomer: null, value: "" });
    //}
  }

  createCustomerCard(customer) {
    return (
      <div className="row">
        <div className="col-md-12">
          <button onClick={this.clearCustomerCard} className="btn btn-danger">
            מחק לקוח
          </button>
          <h4>{customer.firstName + " " + customer.lastName}</h4>
          <h6>{customer.phoneNumber}</h6>
        </div>
      </div>
    );
  }

  render() {
    const {
      value,
      suggestions,
      selectedCustomer,
      products,
      selectedItems,
      itemsCount,
      totalPrice
    } = this.state;

    const inputProps = {
      placeholder: "שם הלקוח",
      className: "form-control",
      value,
      onChange: this.onCustomerChange
    };

    const messages = {
      searchPlaceholder: "Search...",
      noItemsMessage: "No Items...",
      noneSelectedMessage: "None Selected",
      selectedMessage: "delected",
      selectAllMessage: "Select All",
      clearAllMessage: "Clear All",
      disabledItemsTooltip: "You can only select 1 file"
    };

    return (
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="display-4">Order</h1>
              <hr />
              <h3 className="text-center">
                {new Date(this.state.selectedDay).toLocaleDateString()}
              </h3>
              <hr />

              <div className="row ">
                <div className="col-md-6">
                  <h4>לקוח</h4>
                  <div className="form-group">
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequested
                      }
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      getSuggestionValue={this.getSuggestionValue}
                      renderSuggestion={this.renderSuggestion}
                      inputProps={inputProps}
                    />
                    <br />
                    <div>
                      {selectedCustomer ? (
                        this.createCustomerCard(selectedCustomer)
                      ) : (
                        <div>
                          <h2 className="text-center">נא לבחור לקוח</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <h4>מוצרים</h4>
                  <div>
                    {!products ? (
                      <p>
                        There is no product, please add some product by clicking
                        create product into main menu.
                      </p>
                    ) : (
                      <MultiSelect
                        items={products}
                        selectedItems={selectedItems}
                        onChange={this.onProductChange}
                        showSelectedItems={false}
                        showSelectAll={false}
                        messages={messages}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <h1>סה׳׳כ מחיר: {totalPrice} ₪</h1>
                    </div>
                    <div className="col-sm-6">
                      <h1>סה׳׳כ מוצרים: {itemsCount}</h1>
                    </div>
                  </div>
                </div>
              </div>

              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="submit"
                      value="בצע"
                      className="btn btn-info btn-block mt-4"
                      disabled={!selectedCustomer || itemsCount === 0}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Order.propTypes = {
  getCustomers: PropTypes.func,
  getAllProducts: PropTypes.func,
  auth: PropTypes.object,
  customer: PropTypes.object,
  product: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  customer: state.customer,
  product: state.product
});

export default connect(
  mapStateToProps,
  { getCustomers, getAllProducts, createOrder }
)(Order);
