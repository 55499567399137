import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllProducts } from "./products-duck/productAction";
import InputGroup from "../../components/common/InputGroup";
import Spinner from "../../components/common/Spinner";
import { Link } from "react-router-dom";

// React Table
import ReactTable from "react-table";

// React table style ".css"
import "react-table/react-table.css";

class ProductsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      products: [],
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
  }

  fillTableWithSearchFilter(search) {
    var updatedList = this.props.product.products;
    updatedList = updatedList.filter(function(item) {
      return (
        item.name.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.barcode.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.price
          .toString()
          .toLowerCase()
          .search(search.toLowerCase()) !== -1 ||
        item.costPrice
          .toString()
          .toLowerCase()
          .search(search.toLowerCase()) !== -1 ||
        (item.description
          ? item.description.toLowerCase().search(search.toLowerCase()) !== -1
          : false)
      );
    });
    this.setState({ products: updatedList });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "search") {
      const search = e.target.value;
      if (search === "") {
        this.setState({ products: this.props.product.products });
        return;
      }
      this.fillTableWithSearchFilter(search);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.product) {
      this.setState({ products: nextProps.product.products });
    }
  }
  componentDidMount() {
    this.props.getAllProducts();
  }

  render() {
    const { loading } = this.props.product;
    const products = this.state.products;

    const { errors } = this.state;

    let content;

    /*function folderFormatter(folder, ff) {
      return `${folder.prefix} ${folder.num}`;
    }*/

    const columns = [
      {
        Header: "שם מוצר",
        accessor: "name" // String-based value accessors!
      },
      {
        Header: "מק''ט",
        accessor: "barcode"
      },
      {
        Header: "תאור",
        accessor: "description",
        minWidth: 150
      },
      {
        Header: "מחיר עלות",
        accessor: "costPrice",
        Cell: row => {
          return <span>₪ {row.value}</span>;
        }
      },
      {
        Header: "מחיר",
        accessor: "price",
        Cell: row => {
          return <span>₪ {row.value}</span>;
        }
      },
      {
        Header: "ערוך",
        id: "_id",
        accessor: "_id",
        maxWidth: 100,
        Cell: row => {
          return (
            <div>
              <Link to={`product/${row.row._id}`}>ערוך</Link>
            </div>
          );
        }
      }
    ];

    if (products === null || loading) {
      content = <Spinner />;
    } else if (products.length > 0) {
      content = (
        <div>
          <ReactTable
            loading={loading}
            data={products}
            columns={columns}
            pageSize={10}
          />
        </div>
      );
    } else {
      content = <h4>לא נמצא לוקוחות..</h4>;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 m-auto">
            <h1 className="display-4 text-center">רשימת מוצרים</h1>

            <div className="container">
              <div className="row">
                <div className="col-md-8 m-auto">
                  <form onSubmit={this.onSubmit}>
                    <InputGroup
                      placeholder="מחפשים מוצר? הקלידו כאן ונמצא לכם"
                      name="search"
                      value={this.state.search}
                      onChange={this.onChange}
                      error={errors.search}
                      icon="fas fa-search"
                    />
                  </form>
                </div>
              </div>
            </div>

            {content}
          </div>
        </div>
      </div>
    );
  }
}

ProductsTable.propTypes = {
  getAllProducts: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  product: state.product,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getAllProducts }
)(ProductsTable);
