import { combineReducers } from "redux";
import authReducer from "../containers/auth/auth-duck/authReducer";
import errorReducer from "./reducers/errorReducer";
import alertReducer from "../containers/alert/alert-duck/alertReducer";
import customerReducer from "../containers/customers/customers-duck/customerReducer";
import productReducer from "../containers/products/products-duck/productReducer";
import orderReducer from "../containers/order/order-duck/orderReducer";
import documentReducer from "../containers/documents/document-duck/documentReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  alert: alertReducer,
  product: productReducer,
  customer: customerReducer,
  document: documentReducer,
  order: orderReducer
});
