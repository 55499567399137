import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getAllDocuments } from '../documents/document-duck/documentAction';

import DocumentsTable from '../documents/DocumentsTable';

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getData = (pageSize, page) => {
    const { getAllDocuments } = this.props;
    getAllDocuments(pageSize, page);
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 m-auto">
            <h4 className="display-4 text-center">רשימת המסמכים</h4>
            <DocumentsTable getData={this.getData} />
          </div>
        </div>
      </div>
    );
  }
}

Documents.propTypes = {
  getAllDocuments: PropTypes.func,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { getAllDocuments })(Documents);
