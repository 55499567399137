const zeroPad = (num, places) => {
  return num.toString().padStart(places, "0");
};

export const PAYMENT_TYPE = Object.freeze({
  CREDITCARD: "CREDITCARD",
  CHECK: "CHECK",
  CASH: "CASH",
  OTHER: "OTHER"
});

export const DOCUMENT_TYPE = Object.freeze({
  RECEIPT: "RECEIPT",
  INVOICE: "INVOICE",
  BID: "BID",
  CREDIT: "CREDIT"
});

export const PRODUCT_TYPE = Object.freeze({
  UNIT: "UNIT",
  LENGTH: "LENGTH"
});

export const INVOICE_STATUS = Object.freeze({
  PARTIALLY_PAID: "PARTIALLY_PAID",
  FULL_PAID: "FULL_PAID",
  NOT_PAID: "NOT_PAID"
});

export default zeroPad;
