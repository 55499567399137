import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//import classnames from "classnames";
import moment from "moment";
import KeyboardEventHandler from "react-keyboard-event-handler";
// Import Tabs
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

import { createReceipt } from "../Invoice/invoice-duck/InvoiceActions";
import {
  getCustomers,
  getCustomerById
} from "../customers/customers-duck/customerActions";

import { PAYMENT_TYPE, DOCUMENT_TYPE } from "../../utils/util";

import CustomerSelect from "../customers/CustomerSelect";
import CustomerView from "../customers/CustomerView";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import TextAreaFieldGroup from "../../components/common/TextAreaFieldGroup";
import { setAlert } from "../alert/alert-duck/alertActions";

import isEmpty from "../../validation/is-empty";
import Spinner from "../../components/common/Spinner";

export class CreateReceipt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checks: [],
      cash: "",
      totalPrice: "",
      note: "",

      selectedPaymentWay: PAYMENT_TYPE.CASH,

      //check fields
      checkId: 0,
      amount: "",
      date: "",
      number: "",
      bank: "",
      branch: "",
      account: "",

      customers: [],
      selectedCustomer: null,

      errors: {}
    };

    this.setSelectedCustomer = this.setSelectedCustomer.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  onSubmitHandler(e) {
    e.preventDefault();

    const {
      selectedPaymentWay,
      cash,
      selectedCustomer,
      note,
      checks
    } = this.state;

    const customer = {
      fullName: selectedCustomer.firstName + " " + selectedCustomer.lastName,
      idNumber: selectedCustomer.idNumber ? selectedCustomer.idNumber : "",
      phoneNumber: selectedCustomer.phoneNumber,
      customerData: selectedCustomer
    };

    let totalPrice = 0;
    let payment = {};
    if (selectedPaymentWay === PAYMENT_TYPE.CASH) {
      totalPrice = cash;
      payment = { type: selectedPaymentWay, amount: totalPrice };
    } else if (selectedPaymentWay === PAYMENT_TYPE.CHECK) {
      totalPrice = this.calculateTotalChecks();
      payment = {
        type: selectedPaymentWay,
        amount: this.calculateTotalChecks(),
        check: checks
      };
    } else {
      return;
    }

    const {
      match: { params }
    } = this.props;

    const receiptData = {
      type: DOCUMENT_TYPE.RECEIPT,
      totalPrice: totalPrice,
      customer: customer,
      payment,
      note
    };
    if (params.id) receiptData.invId = params.id;
    this.props.createReceipt(receiptData, this.props.history);
  }
  checkFields() {
    const errors = {};

    if (isEmpty(this.state.amount)) errors.amount = "שדה חובה";
    if (isEmpty(this.state.date)) errors.date = "שדה חובה";
    if (isEmpty(this.state.number)) errors.number = "שדה חובה";
    if (isEmpty(this.state.bank)) errors.bank = "שדה חובה";
    if (isEmpty(this.state.branch)) errors.branch = "שדה חובה";
    if (isEmpty(this.state.account)) errors.account = "שדה חובה";

    this.setState({ errors });
    if (!isEmpty(errors)) return false;

    return true;
  }

  onSubmit(e) {
    e.preventDefault();
    if (!this.checkFields()) {
      this.forceUpdate();

      return;
    }

    const { amount, date, number, bank, branch, account, checkId } = this.state;

    this.state.checks.push({
      amount,
      date,
      number,
      bank,
      branch,
      account,
      id: checkId
    });

    this.setState({
      amount: "",
      date: "",
      number: "",
      bank: "",
      branch: "",
      account: "",
      checkId: checkId + 1
    });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    if (params.customer) {
      this.props.getCustomerById(params.customer);
    } else {
      if (this.props.customer.customers) {
        this.setState({ customers: this.props.customer.customers });
      } else {
        this.props.getCustomers();
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.customer.customers) {
      this.setState({ customers: nextProps.customer.customers });
    }
    if (nextProps.customer.customer) {
      this.setState({ selectedCustomer: nextProps.customer.customer });
    }
  }

  setSelectedCustomer(customer) {
    const { selectedCustomer } = this.state;
    if (customer === selectedCustomer) {
    } else {
      this.setState({ selectedCustomer: customer });
    }
  }

  calculateTotalChecks = () => {
    let total = this.state.checks.reduce((sum, check) => {
      return sum + Number(check.amount);
    }, 0);

    return total;
  };

  selectPaymentWayHandler(index) {
    this.setState({
      selectedPaymentWay: index === 0 ? PAYMENT_TYPE.CASH : PAYMENT_TYPE.CHECK
    });
  }

  renderEditable = cellInfo => {
    return (
      <div
        style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const checks = [...this.state.checks];
          checks[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          this.setState({ checks });
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.checks[cellInfo.index][cellInfo.column.id]
        }}
      />
    );
  };

  onRemoveRowHandler(e, row) {
    const items = this.state.checks;
    const filteredItems = items.filter(function(item) {
      return item.id !== row.id;
    });
    this.setState({ checks: filteredItems });
  }

  disableSubmit = () => {
    const { selectedCustomer, cash } = this.state;
    if (!selectedCustomer) return true;
    if (this.calculateTotalChecks() !== 0 || cash) return false;
    return true;
  };

  onF3Down = (key, e) => {
    // Copy the last line of checks list
    const { checks, checkId } = this.state;
    let lastCheck = { ...checks[checks.length - 1] };
    if (checks.length > 0) {
      let lastDate = moment(lastCheck.date);

      const date = moment(lastDate).add(1, "M");
      checks.push({
        ...lastCheck,
        number: Number(lastCheck.number) + 1,
        date: moment(date).format("YYYY-MM-DD"),
        id: checkId
      });
      this.setState({ checkId: checkId + 1 });
    } else {
      this.props.setAlert("אין שיקים כדי להעתיק.", "warn", 2000);
    }
  };

  render() {
    const {
      checks,
      cash,
      note,
      customers,
      number,
      amount,
      date,
      bank,
      branch,
      account,
      errors
    } = this.state;

    const columns = [
      {
        Header: "מספר שיק",
        accessor: "number",
        Cell: this.renderEditable
      },
      {
        Header: "מספר בנק",
        accessor: "bank",
        Cell: this.renderEditable
      },
      {
        Header: "מספר סניף",
        accessor: "branch",
        Cell: this.renderEditable
      },
      {
        Header: "חשבון",
        accessor: "account",
        Cell: this.renderEditable
      },
      {
        Header: "תאריך",
        accessor: "date",
        Cell: this.renderEditable
      },
      {
        Header: "סכום שיק",
        accessor: "amount",
        Cell: this.renderEditable
      },
      {
        Header: "מחק",
        id: "id",
        maxWidth: 80,
        accessor: row => (
          <div className="col-sm-12 text-center btn btn-danger p-0 m-0">
            <button onClick={e => this.onRemoveRowHandler(e, row)}>
              {" "}
              <i className="fas fa-trash-alt text-light" />
            </button>
          </div>
        )
      }
    ];

    return (
      <div className="invoice">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="display-4 text-center">קבלה</h1>
              <hr />
              <div className="row">
                {this.props.match.params.customer ? (
                  <div className="col-md-12">
                    {this.state.selectedCustomer ? (
                      <CustomerView customer={this.state.selectedCustomer} />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                ) : (
                  <div className="col-md-12">
                    <h4>בחר לקוח</h4>
                    <div className="form-group">
                      {customers ? (
                        <CustomerSelect
                          setSelectedCustomer={this.setSelectedCustomer}
                          customers={customers}
                        />
                      ) : (
                        "Loadding..."
                      )}
                    </div>
                  </div>
                )}
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <h4 className="">תשלום</h4>
                  <br />
                  <div className="form-group">
                    <Tabs
                      defaultIndex={0}
                      onSelect={index => this.selectPaymentWayHandler(index)}
                    >
                      <TabList>
                        <Tab>Cash</Tab>
                        <Tab>Checks</Tab>
                      </TabList>

                      <TabPanel>
                        <TextFieldGroup
                          placeholder="* סכום"
                          name="cash"
                          type="Number"
                          value={cash}
                          onChange={this.onChange}
                          error={errors.cash}
                        />
                      </TabPanel>
                      <TabPanel>
                        <form onSubmit={this.onSubmit}>
                          <KeyboardEventHandler
                            handleKeys={["a", "b", "c", "f1", "f2", "f3"]}
                            onKeyEvent={this.onF3Down}
                          />
                          <h3 className="p-2">הוספת שיק חדש</h3>
                          <div className="row">
                            <div className="col-md-4">
                              <TextFieldGroup
                                placeholder="* מס׳ שיק"
                                info="מספר שיק"
                                name="number"
                                value={number}
                                type="Number"
                                onChange={this.onChange}
                                error={errors.number}
                              />
                            </div>
                            <div className="col-md-4">
                              <TextFieldGroup
                                placeholder="* תאריך"
                                info="תאריך "
                                name="date"
                                value={date}
                                type="Date"
                                onChange={this.onChange}
                                error={errors.date}
                              />
                            </div>
                            <div className="col-md-4">
                              <TextFieldGroup
                                placeholder="* סכום"
                                info="סכום שיק"
                                name="amount"
                                value={amount}
                                type="Number"
                                onChange={this.onChange}
                                error={errors.amount}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <TextFieldGroup
                                placeholder="* מס׳ בנק"
                                info="מספר בנק"
                                name="bank"
                                value={bank}
                                type="Number"
                                onChange={this.onChange}
                                error={errors.bank}
                              />
                            </div>
                            <div className="col-md-4">
                              <TextFieldGroup
                                placeholder="* מס׳ סניף"
                                info="מספר סניף"
                                name="branch"
                                value={branch}
                                type="Number"
                                onChange={this.onChange}
                                error={errors.branch}
                              />
                            </div>
                            <div className="col-md-4">
                              <TextFieldGroup
                                placeholder="* מס׳ חשבון"
                                info="מספר חשבון"
                                name="account"
                                value={account}
                                type="Number"
                                onChange={this.onChange}
                                error={errors.account}
                              />
                            </div>
                          </div>
                          <input
                            className="btn btn-sm btn-success btn-block"
                            type="submit"
                            disabled={account === ""}
                            value="Add"
                          />
                        </form>
                        <br />
                        <div>
                          <ReactTable
                            data={checks}
                            columns={columns}
                            defaultPageSize={10}
                            className="-striped -highlight"
                          />
                        </div>
                        <div className="d-flex flex-row-reverse bg-dark text-white p-4">
                          <div className="py-0 px-5 text-right">
                            <div className="mb-2">סה׳׳כ שיקים</div>
                            <div className="h2 font-weight-light">
                              {this.calculateTotalChecks().toFixed(0)} ₪
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                  <TextAreaFieldGroup
                    name="note"
                    placeholder="הערה"
                    value={note}
                    onChange={this.onChange}
                  />
                  <div className="row">
                    <div className="col-md-12">
                      <form onSubmit={this.onSubmitHandler}>
                        <input
                          className="btn btn-info btn-lg btn-block mt-4"
                          type="submit"
                          value="בצע"
                          disabled={this.disableSubmit()}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
CreateReceipt.propTypes = {
  customer: PropTypes.object,
  createReceipt: PropTypes.func.isRequired,
  getCustomers: PropTypes.func,
  getCustomerById: PropTypes.func,
  setAlert: PropTypes.func
};
const mapStateToProps = state => ({
  auth: state.auth,
  customer: state.customer
});
export default connect(
  mapStateToProps,
  { createReceipt, getCustomers, setAlert, getCustomerById }
)(CreateReceipt);
