import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Autosuggest from 'react-autosuggest';
import ReactDataGrid from 'react-data-grid';
import classnames from 'classnames';

import CustomerView from '../customers/CustomerView';
import CustomerSelect from '../customers/CustomerSelect';
import TextAreaFieldGroup from '../../components/common/TextAreaFieldGroup';
import EditableText from '../../components/common/EditableText';

import { PRODUCT_TYPE } from '../../utils/util';
import VAT from '../../utils/const';

export class DocumentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      value: '',
      sending: false,

      products: [],
      suggestions: [],
      selectedProducts: [],

      selectedCustomer: null,

      withVat: false,
      discount: 0,

      note: '',

      finalPrice: 0,
      errors: {},
    };
    this.calculatedVat = VAT / 100 + 1;

    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.setSelectedCustomer = this.setSelectedCustomer.bind(this);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.onFinalPriceEdit = this.onFinalPriceEdit.bind(this);
    this.calculatFinalPrice = this.calculatFinalPrice.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const { selectedCustomer, selectedProducts, withVat, discount, note } =
      this.state;

    let _items = [];
    for (let i = 0; i < selectedProducts.length; i++) {
      const element = selectedProducts[i];

      _items.push({
        product: element,
        name: element.name,
        type: element.type,
        quantity: element.quantity,
        length: element.length,
        discount: element.discount,
        originalPrice: element.originalPrice,
        price: element.price,
        costPrice: element.costPrice,
      });
    }

    const customer = {
      fullName: selectedCustomer.firstName + ' ' + selectedCustomer.lastName,
      idNumber: selectedCustomer.idNumber ? selectedCustomer.idNumber : '',
      phoneNumber: selectedCustomer.phoneNumber,
      customerData: selectedCustomer,
    };

    const documentData = {
      totalPrice: this.calculatFinalPrice(),
      totalPriceBeforeDiscount: this.calculatPriceBeforeDiscount(),
      totalPriceBeforeVat:
        this.calculatFinalPrice() / (withVat ? this.calculatedVat : 1),
      vat: withVat ? VAT : 0,
      discount: discount,
      customer: customer,
      items: _items,
      note,
    };

    this.setState({ sending: true });
    this.props.documentCallBack(documentData);
  }

  componentDidMount() {
    if (this.props.products) {
      this.setState({ products: this.props.products });
    }
    if (this.props.customers) {
      this.setState({ customers: this.props.customers });
    }

    // Optinal props
    if (this.props.withVat) {
      this.setState({ withVat: this.props.withVat }, () => {
        this.calculatFinalPrice();
      });
    }
    if (this.props.editMode) {
      this.setState({ editMode: this.props.editMode });
    }
    if (this.props.discount) {
      this.setState({ discount: this.props.discount }, () => {
        this.calculatFinalPrice();
      });
    }
    if (this.props.note) {
      this.setState({ note: this.props.note });
    }
    if (this.props.selectedCustomer) {
      this.setSelectedCustomer(this.props.selectedCustomer);
    }
    if (this.props.items) {
      const { selectedProducts } = this.state;
      this.props.items.map((item) => {
        return selectedProducts.push({
          ...item.product,
          quantity: item.quantity,
          length: item.length,
          totalPrice: Number(
            (item.product.price * item.quantity * item.length).toFixed(2)
          ),
          totalCostPrice: Number(
            (item.product.costPrice * item.quantity * item.length).toFixed(2)
          ),
          r: Number(
            (
              item.product.price * item.quantity * item.length -
              item.product.costPrice * item.quantity * item.length
            ).toFixed(2)
          ),
        });
      });
      this.calculatFinalPrice();
    }
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  //#region Product suggestion
  getSuggestions(value) {
    //const v = value.replace("/", "BackSlash").replace(".", "dot");
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return [];
    }

    //const regex = new RegExp("\\w" + escapedValue + "|\\b" + escapedValue, "g");
    ///£\S+/g
    //const regex3 = new RegExp(`(?:\\b${escapedValue})+|(?:\\B${escapedValue})`,"g"  );

    /*
    const allowedSeparator = "\\s,;\"'|";

    const regex2 = new RegExp(
      `(^.*[${allowedSeparator}]${escapedValue}$)|(^${escapedValue}[${allowedSeparator}].*)|(^${escapedValue}$)|(^.*[${allowedSeparator}]${escapedValue}[${allowedSeparator}].*$)`,
      "g"
    );
    return this.state.products.filter(
      product => (product.name + " " + product.barcode).search(regex2) !== -1
    );
    */

    const regex = new RegExp(escapedValue, 'i');
    return this.state.products.filter((product) =>
      regex.test(product.name + ' ' + product.barcode)
    );
  }

  setSelectedProduct(product) {
    const { selectedProducts } = this.state;
    product = {
      ...product,
      quantity: 1,
      length: 1,
      type: product.type,
      totalPrice: Number(product.price.toFixed(2)),
      totalCostPrice: Number(product.costPrice.toFixed(2)),
      r: Number((product.price - product.costPrice).toFixed(2)),
    };
    selectedProducts.push(product);
    this.setState({ value: '' });
    this.calculatFinalPrice();
  }

  onSuggestionSelected(
    suggestion,
    suggestionValue,
    suggestionIndex,
    sectionIndex,
    method
  ) {
    this.setSelectedProduct(suggestionValue.suggestion);
    this.clearInput();
  }

  getSuggestionValue(suggestion) {
    return suggestion.name + ' ' + suggestion.barcode;
  }

  renderSuggestion(suggestion) {
    return (
      <span>
        {suggestion.name + ' '} <small>{suggestion.barcode}</small>
      </span>
    );
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };
  onProductChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  clearInput = () => {
    this.setState({
      value: '',
    });
  };

  //#endregion

  onGridRowsUpdated = async ({ fromRow, toRow, updated }) => {
    // disable editable to normal products
    if (
      this.state.selectedProducts[fromRow].type === PRODUCT_TYPE.UNIT &&
      updated.length
    ) {
      return;
    }

    // Check if length is number
    if (updated.length) {
      if (isNaN(updated.length)) {
        return;
      }
    }
    // Check if quantity is number
    if (updated.quantity) {
      if (isNaN(updated.quantity)) {
        return;
      }
    }

    // Update product
    await this.setState((state) => {
      const selectedProducts = state.selectedProducts.slice();
      let length = updated.length
        ? updated.length
        : selectedProducts[fromRow].length;
      let quantity = updated.quantity
        ? updated.quantity
        : selectedProducts[fromRow].quantity;

      const unit = length * quantity;

      for (let i = fromRow; i <= toRow; i++) {
        selectedProducts[i] = {
          ...selectedProducts[i],
          ...updated,
          totalPrice: Number((selectedProducts[i].price * unit).toFixed(2)),
          totalCostPrice: Number(
            (selectedProducts[i].costPrice * unit).toFixed(2)
          ),
          r: Number(
            (
              selectedProducts[i].price * unit -
              selectedProducts[i].costPrice * unit
            ).toFixed(2)
          ),
        };
      }
      return { selectedProducts };
    });
    this.calculatFinalPrice();
  };

  async onChange(e) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    await this.setState({
      [name]: value,
    });
    this.calculatFinalPrice();
  }

  calculatFinalPrice = () => {
    let price = this.state.selectedProducts.reduce((sum, product) => {
      return sum + product.totalPrice;
    }, 0);

    if (this.state.discount > 0) {
      price = price * ((100 - this.state.discount) / 100);
    }
    if (this.state.withVat) {
      //TODO move vat value to database
      price = price * this.calculatedVat;
    }
    this.setState({ finalPrice: price });
    return price;
  };

  calculatPriceBeforeDiscount = () => {
    let price = this.state.selectedProducts.reduce((sum, product) => {
      return sum + product.totalPrice;
    }, 0);

    return price;
  };

  setSelectedCustomer(customer) {
    const { selectedCustomer } = this.state;
    if (customer === selectedCustomer) {
    } else {
      this.setState({ selectedCustomer: customer });
    }
  }

  getCellActions = (column, row) => {
    const cellActions = [
      {
        icon: <i className="fas fa-trash-alt text-dark" />,
        callback: () => {
          this.setState({
            selectedProducts: this.arrayRemove(
              this.state.selectedProducts,
              row
            ),
          });
        },
      },
    ];
    return column.key === 'action' ? cellActions : null;
  };

  arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  };

  async onFinalPriceEdit(newValue) {
    const { finalPrice, withVat } = this.state;
    if (newValue >= finalPrice) {
      this.calculatFinalPrice();
      return;
    }
    const p = this.calculatPriceBeforeDiscount();
    const v = withVat ? this.calculatedVat : 1;
    let discount = (100 * (p * v - newValue)) / (v * p);
    await this.setState({ discount: discount.toFixed(2) });
    this.calculatFinalPrice();
  }

  render() {
    const { isAdmin } = this.props.auth.user;
    const {
      editMode,
      value,
      suggestions,
      discount,
      selectedProducts,
      customers,
      selectedCustomer,
      note,
      sending,
    } = this.state;

    const inputProps = {
      placeholder: 'שם מוצר | מק׳׳ט',
      className: 'form-control',
      value,
      onChange: this.onProductChange,
    };

    const productsColumns = [
      { key: 'action', name: 'מחק' },
      { key: 'totalPrice', name: 'מחיר סופי' },
      { key: 'price', name: 'מחיר ליחידה' },
      { key: 'quantity', name: 'כמות', editable: true },
      { key: 'length', name: 'אורך', editable: true },
      { key: 'barcode', name: 'מק׳׳ט' },
      { key: 'name', name: 'שם מוצר' },
    ];

    const productsColumnsAdmin = [
      { key: 'action', name: 'מחק' },
      { key: 'r', name: 'סכ׳׳ה רוח' },
      { key: 'totalCostPrice', name: 'סכ׳׳ה מחיר עלות' },
      { key: 'totalPrice', name: 'מחיר סופי' },
      { key: 'price', name: 'מחיר ליחידה' },
      { key: 'quantity', name: 'כמות', editable: true },
      { key: 'length', name: 'אורך', editable: true },
      { key: 'barcode', name: 'מק׳׳ט' },
      { key: 'name', name: 'שם מוצר' },
    ];

    return (
      <div className="invoice">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1
                className={`display-4 text-center ${
                  this.props.title.indexOf('זיכוי') > -1 ? 'text-danger' : ''
                }`}
              >
                {this.props.title}
              </h1>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  {editMode ? '' : <h4>בחר לקוח</h4>}
                  <div className="form-group">
                    {editMode ? (
                      <CustomerView customer={selectedCustomer} />
                    ) : customers ? (
                      <CustomerSelect
                        setSelectedCustomer={this.setSelectedCustomer}
                        customers={customers}
                      />
                    ) : (
                      'Loadding...'
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <h4>בחר מוצר</h4>
                  <div className="form-group">
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequested
                      }
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      onSuggestionSelected={this.onSuggestionSelected}
                      getSuggestionValue={this.getSuggestionValue}
                      renderSuggestion={this.renderSuggestion}
                      inputProps={inputProps}
                    />
                    <br />

                    <div dir="ltr">
                      <ReactDataGrid
                        columns={
                          isAdmin ? productsColumnsAdmin : productsColumns
                        }
                        rowGetter={(i) => selectedProducts[i]}
                        onGridRowsUpdated={this.onGridRowsUpdated}
                        minHeight={500}
                        rowsCount={50}
                        getCellActions={this.getCellActions}
                        callSelection={true}
                        enableCellSelect={true}
                      />
                    </div>
                    <br />
                    <TextAreaFieldGroup
                      name="note"
                      placeholder="Note"
                      value={note}
                      onChange={this.onChange}
                    />
                    <hr />
                    <div className="summary">
                      <dl className="row">
                        <dt className="col-sm-5 text-truncate">מספר שורות</dt>
                        <dd className="col-sm-6">{selectedProducts.length}</dd>

                        <dt className="col-sm-5 text-truncate"> הנחה ב% </dt>
                        <dd className="col-sm-6">
                          <label className="form-group">
                            <input
                              className="form-control"
                              name="discount"
                              value={discount}
                              type="number"
                              min="0"
                              max="50"
                              onChange={this.onChange}
                            />
                          </label>
                        </dd>

                        {discount !== 0 ? (
                          <dt className="col-sm-5 text-truncate">
                            מחיר לפני הנחה
                          </dt>
                        ) : (
                          ''
                        )}
                        {discount !== 0 ? (
                          <dd className="col-sm-6">
                            {this.calculatPriceBeforeDiscount()}
                          </dd>
                        ) : (
                          ''
                        )}

                        <dt className="col-sm-5 text-truncate">מע׳׳מ</dt>
                        <dd className="col-sm-6">
                          <div className="checkbox">
                            <label>
                              <input
                                type="checkbox"
                                value={this.state.withVat}
                                checked={this.state.withVat}
                                onChange={this.onChange}
                                name="withVat"
                              />
                              <span className="cr">
                                <i className="cr-icon fa fa-check" />
                              </span>
                            </label>
                          </div>
                        </dd>

                        {isAdmin ? (
                          <div className="row col-sm-12">
                            <dt className="col-sm-5">
                              <h3>סכ׳׳ה עלות</h3>
                            </dt>
                            <dd className="col-sm-6">
                              <h3>
                                {this.state.selectedProducts
                                  .reduce((sum, product) => {
                                    return sum + product.totalCostPrice;
                                  }, 0)
                                  .toFixed(2)}{' '}
                                ₪
                              </h3>
                            </dd>
                            <dt className="col-sm-5">
                              <h3>סכ׳׳ה רוח</h3>
                            </dt>
                            <dd className="col-sm-6">
                              <h3>
                                {(
                                  this.state.finalPrice -
                                  this.state.selectedProducts.reduce(
                                    (sum, product) => {
                                      return sum + product.totalCostPrice;
                                    },
                                    0
                                  )
                                ).toFixed(2)}{' '}
                                ₪
                              </h3>
                            </dd>
                          </div>
                        ) : (
                          ''
                        )}

                        <dt className="col-sm-5 display-4 text-success">
                          סכ׳׳ה מחיר
                        </dt>
                        <dd className="col-sm-6 display-4 text-success">
                          {' '}
                          <EditableText
                            value={this.state.finalPrice}
                            onChange={this.onFinalPriceEdit}
                            suffix="₪"
                          />
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="submit"
                  value="בצע"
                  className={classnames('btn btn-secondary btn-lg btn-block', {
                    'btn-success':
                      selectedCustomer && selectedProducts.length !== 0,
                  })}
                  disabled={
                    !selectedCustomer ||
                    selectedProducts.length === 0 ||
                    sending
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

DocumentForm.propTypes = {
  auth: PropTypes.object,
  title: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  customers: PropTypes.array.isRequired,
  documentCallBack: PropTypes.func.isRequired,

  editMode: PropTypes.bool,
  withVat: PropTypes.bool,
  discount: PropTypes.number,
  note: PropTypes.string,
  selectedCustomer: PropTypes.object,
  items: PropTypes.array,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  product: state.product,
  customer: state.customer,
});

export default connect(mapStateToProps, {})(DocumentForm);
