import React from "react";
import PropTypes from "prop-types";

import { PAYMENT_TYPE } from "../../utils/util";

const PaymentTable = ({ payment }) => {
  const titles = [
    " ",
    "תאריך",
    "מספר שיק",
    "בנק",
    "סניף",
    "מספר חשבון",
    "סכום"
  ];
  const cash = <h2>מזומן: {payment.amount.toFixed(2)} ₪</h2>;
  const checks = (
    <table className="table">
      <thead>
        <tr>
          {titles.map((t, i) => (
            <th
              key={i}
              className="border-0 text-uppercase small font-weight-bold"
            >
              {t}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {payment.check.map((item, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{new Date(item.date).toLocaleDateString()}</td>
            <td>{item.number}</td>
            <td>{item.bank}</td>
            <td>{item.branch}</td>
            <td>{item.account}</td>

            <td>{item.amount.toFixed(2)} ₪</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  return <div>{payment.type === PAYMENT_TYPE.CHECK ? checks : cash}</div>;
};

PaymentTable.propTypes = {
  payment: PropTypes.object.isRequired
};

export default PaymentTable;
