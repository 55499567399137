import React, { Component } from "react";
import PropTypes from "prop-types";

class EditableText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      isInEditMode: false,
      prefix: "",
      suffix: ""
    };

    this.changeEditMode = this.changeEditMode.bind(this);
    this.updateComponentValue = this.updateComponentValue.bind(this);
  }
  componentDidMount() {
    this.setState({ value: this.props.value });
    if (this.props.prefix) this.setState({ prefix: this.props.prefix });
    if (this.props.suffix) this.setState({ suffix: this.props.suffix });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.value) this.setState({ value: nextProps.value });
  }

  changeEditMode() {
    this.setState({ isInEditMode: !this.state.isInEditMode });
  }

  updateComponentValue() {
    this.setState({
      isInEditMode: false,
      value: Number(this.refs.theTextInput.value)
    });
    this.props.onChange(Number(this.refs.theTextInput.value));
  }

  onEnterPress = event => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.updateComponentValue();
    }
  };

  handleFocus = event => event.target.select();

  renderEditView() {
    const { value } = this.state;
    return (
      <div className="input-group col-sm-6 text-left" dir="ltr">
        <input
          className="form-control form-control-lg"
          type="number"
          defaultValue={value.toFixed(0)}
          onKeyPress={this.onEnterPress}
          onFocus={this.handleFocus}
          ref="theTextInput"
          aria-describedby="basic-addon1"
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary btn-danger"
            onClick={this.changeEditMode}
          >
            X
          </button>
          <button
            className="btn btn-success btn-outline-secondary"
            onClick={this.updateComponentValue}
          >
            סיום
          </button>
        </div>
      </div>
    );
  }

  renderDefaultView() {
    const { value, prefix, suffix } = this.state;
    return (
      <div onDoubleClick={this.changeEditMode}>
        {prefix + " " + (value ? value.toFixed(0) : "0") + " " + suffix}
      </div>
    );
  }
  render() {
    return this.state.isInEditMode
      ? this.renderEditView()
      : this.renderDefaultView();
  }
}

EditableText.propTypes = {
  value: PropTypes.number.isRequired,
  isInEditMode: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

EditableText.defaultProps = {
  isInEditMode: false
};

export default EditableText;
