import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Notifications
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Alert = ({ alerts }) => {
  const notify = alert => {
    switch (alert.alertType) {
      case "success":
        return toast.success(
          <label>
            <i className="fas fa-check" /> {alert.msg}
          </label>,
          {
            position: toast.POSITION.TOP_LEFT,
            autoClose: alert.timeout
          }
        );
      case "error":
        return toast.error(
          <label>
            <i className="fas fa-exclamation-circle" /> {alert.msg}
          </label>,
          {
            position: toast.POSITION.TOP_LEFT,
            autoClose: alert.timeout
          }
        );
      case "warn":
        return toast.warn(
          <label>
            <i className="fas fa-exclamation-triangle" /> {alert.msg}
          </label>,
          {
            position: toast.POSITION.TOP_LEFT,
            autoClose: alert.timeout
          }
        );
      case "info":
        return toast.info(
          <label>
            <i className="fas fa-info" /> {alert.msg}
          </label>,
          {
            position: toast.POSITION.TOP_LEFT,
            autoClose: alert.timeout
          }
        );
      default:
        return toast(
          <label>
            <i className="fas fa-thumbtack" /> {alert.msg}
          </label>,
          {
            position: toast.POSITION.TOP_LEFT,
            autoClose: alert.timeout
          }
        );
    }
  };

  return (
    <div>
      <ToastContainer pauseOnFocusLoss={true} rtl />
      {alerts !== null &&
        alerts.length > 0 &&
        alerts.map(alert => {
          return notify(alert) === "" ? "" : "";
        })}
    </div>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(
  mapStateToProps,
  {}
)(Alert);
