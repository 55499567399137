import uuid from "uuid";
import { SET_ALER, REMOVE_ALERT } from "../../../app-duck/actions/types";

export const setAlert = (msg, alertType, timeout = 5000) => dispatch => {
  const id = uuid.v4();
  dispatch({
    type: SET_ALER,
    payload: { msg, alertType, id, timeout }
  });

  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};
