import axios from "axios";
import { setAlert } from "../../alert/alert-duck/alertActions";

import {
  INVOICE_DATA,
  INVOICE_LOADING,
  GET_ERRORS
} from "../../../app-duck/actions/types";

export const createInvoice = (invoiceData, history) => dispatch => {
  axios
    .post("/api/invoices/", invoiceData)
    .then(res => {
      dispatch({
        type: INVOICE_DATA,
        payload: res.data
      });
      history.push(`/document/${res.data._id}`);
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

export const createBid = (bidData, history, isNew = true) => dispatch => {
  axios
    .post("/api/bid/", bidData)
    .then(res => {
      dispatch({
        type: INVOICE_DATA,
        payload: res.data
      });
      dispatch(
        setAlert(
          isNew ? "הפקת הצעת מחיר בהצלחה" : "עדכנת הצעת מחיר בהצלחה",
          isNew ? "success" : "info"
        )
      );
      history.push(`/document/${res.data._id}`);
    })
    .catch(err => {
      console.error(err);
      dispatch(
        setAlert(
          isNew ? "שגיאה בהפקת הצעת מחיר " : "שגיאה בעדכן הצעת מחיר בהצלחה",
          "error"
        )
      );
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

export const createCredit = (creditData, history) => dispatch => {
  axios
    .post("/api/credit/", creditData)
    .then(res => {
      dispatch({
        type: INVOICE_DATA,
        payload: res.data
      });
      history.push(`/document/${res.data._id}`);
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

export const createReceipt = (receiptData, history) => dispatch => {
  axios
    .post("/api/receipt/", receiptData)
    .then(res => {
      dispatch({
        type: INVOICE_DATA,
        payload: res.data
      });
      history.push(`/document/${res.data._id}`);
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Invoice Loading
export const setInvoiceLoading = () => {
  return {
    type: INVOICE_LOADING
  };
};
