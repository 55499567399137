import {
  GET_PRODUCT,
  GET_PRODUCTS,
  CLEAR_CURRENT_PRODUCT,
  PRODUCTS_LOADING,
  CLEAR_PRODUCT_NOTIFICATION,
  PRODUCT_NOTIFICATION
} from "../../../app-duck/actions/types";

const initialState = {
  product: null,
  products: null,
  loading: null,
  notification: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PRODUCTS_LOADING:
      return { ...state, loading: true };
    case GET_PRODUCT:
      return {
        ...state,
        product: action.payload,
        loading: false
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false
      };
    case CLEAR_CURRENT_PRODUCT:
      return {
        ...state,
        product: null
      };
    case PRODUCT_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      };
    case CLEAR_PRODUCT_NOTIFICATION:
      return {
        ...state,
        notification: null
      };
    default:
      return state;
  }
}
