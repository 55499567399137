import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";

const CustomerView = ({ customer }) => {
  return (
    <div className="row">
      <div className="col-md-10 mx-auto">
        <div className="row">
          <div className="col-md-8">
            <h4>
              <Link to={`/profile/${customer._id}`}>
                {customer.firstName + " " + customer.lastName}
              </Link>
            </h4>
            <h6 className="text-muted">
              <a href={"tel:" + customer.phoneNumber}>
                <i className="fas fa-phone" /> {customer.phoneNumber}
              </a>
            </h6>
            {customer.idNumber ? (
              <h6 className="text-muted">
                <i className="fas fa-id-card" /> {customer.idNumber}
              </h6>
            ) : (
              ""
            )}

            {customer.email ? (
              <h6 className="text-muted">
                <i className="fas fa-envelope" /> {customer.email}
              </h6>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-4 ">
            <h3
              dir="ltr"
              className={classnames("text-success", {
                "text-danger": customer.account.total < 0
              })}
            >
              {customer.account.total.toFixed(2)} ₪
            </h3>
            {customer.account.lastTransaction ? (
              <h6>
                עסקה אחרונה{" "}
                {new Date(
                  customer.account.lastTransaction
                ).toLocaleDateString()}
              </h6>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerView.propTypes = {
  customer: PropTypes.object.isRequired
};

CustomerView.defaultProps = {
  customer: {
    account: {
      total: 0,
      lastTransaction: "2000-01-01T00:00:01.280Z"
    },
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: ""
  }
};

export default CustomerView;
