import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import {
  setCurrentUser,
  logoutUser
} from "./containers/auth/auth-duck/authActions";
import { clearCurrentProfile } from "./app-duck/actions/profileActions";

import { Provider } from "react-redux";
import store from "./app-duck/store";

import Navbar2 from "./components/layout/Navbar2";
import Footer from "./components/layout/Footer";
//import Landing from "./components/layout/Landing";

import Login from "./containers/auth/Login";

import Alert from "./containers/alert/Alert";

import "./App.css";

import AppRoutes from "./AppRoutes";

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = "/login";
  }
}
const LoginContainer = () => (
  <div>
    <Route exact path="/" render={() => <Redirect to="/login" />} />
    <Route exact path="/login" component={Login} />
  </div>
);

// const RegisterContainer = () => (
//   <div>
//     <Route exact path="/" render={() => <Redirect to="/register" />} />
//     <Route exact path="/register" component={Register} />
//   </div>
// );

const DefaultContainer = () => (
  <div className="App">
    <Navbar2 />
    <Route exact path="/" component={LoginContainer} />
    {AppRoutes()}
    <Footer />
  </div>
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Alert />
            <Switch>
              <Route exact path="/(login)" component={LoginContainer} />

              <Route component={DefaultContainer} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
