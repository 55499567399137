import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import { createCustomer } from "./customers-duck/customerActions";

class CreateCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      idNumber: "",
      email: "",
      phoneNumber: "",
      birthDate: "",

      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const customerData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      idNumber: this.state.idNumber,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      birthDate: this.state.birthDate
    };

    this.props.createCustomer(customerData, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="create-profile">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">הוספת לקוח חדש</h1>
              <p className="lead text-center">טופס הקמת לקוח חדש למערכת</p>
              <small className="d-block pb-3">* = שדות חובה</small>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="* שם פרטי"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.onChange}
                  error={errors.firstName}
                />

                <TextFieldGroup
                  placeholder="* שם משפחה"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.onChange}
                  error={errors.lastName}
                />
                <TextFieldGroup
                  placeholder="מס' ת''ז"
                  name="idNumber"
                  value={this.state.idNumber}
                  onChange={this.onChange}
                  error={errors.idNumber}
                  type="Numbers"
                  info="מספר תעודת זהוי או דרכון"
                />
                <TextFieldGroup
                  placeholder="דואר אליקטרוני"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                  type="email"
                  info="למשל Example@domain.com"
                />
                <TextFieldGroup
                  placeholder="* מס' נייד"
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.onChange}
                  error={errors.phoneNumber}
                  type="numbers"
                  info="מספר טלפון נייד כולל קידומת"
                />
                <TextFieldGroup
                  placeholder="birthDate"
                  name="birthDate"
                  value={this.state.birthDate}
                  onChange={this.onChange}
                  error={errors.birthDate}
                  type="date"
                />

                <input
                  type="submit"
                  value="בצע"
                  className="btn btn-info btn-block mt-4"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateCustomer.propTypes = {
  customer: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  customer: state.customer,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { createCustomer }
)(withRouter(CreateCustomer));
