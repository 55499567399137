import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import InputGroup from "../../components/common/InputGroup";
import Spinner from "../../components/common/Spinner";
import calssnames from "classnames";

// React Table
import ReactTable from "react-table";

// React table style ".css"
import "react-table/react-table.css";

import { getCustomers } from "./customers-duck/customerActions";
class CustomerTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      customers: [],

      errors: {}
    };
    this.onChange = this.onChange.bind(this);
  }

  fillTableWithSearchFilter(search) {
    var updatedList = this.props.customer.customers;
    updatedList = updatedList.filter(function(item) {
      return (
        item.firstName.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.lastName.toLowerCase().search(search.toLowerCase()) !== -1 ||
        item.phoneNumber
          .toString()
          .toLowerCase()
          .search(search.toLowerCase()) !== -1 ||
        (item.idNumber
          ? item.idNumber.toLowerCase().search(search.toLowerCase()) !== -1
          : false) ||
        (item.email
          ? item.email.toLowerCase().search(search.toLowerCase()) !== -1
          : false)
      );
    });
    this.setState({ customers: updatedList });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "search") {
      const search = e.target.value;
      if (search === "") {
        this.setState({ customers: this.props.customer.customers });
        return;
      }
      this.fillTableWithSearchFilter(search);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.customer) {
      this.setState({ customers: nextProps.customer.customers });
    }
  }
  componentDidMount() {
    this.props.getCustomers();
  }

  render() {
    const { loading } = this.props.customer;
    const customers = this.state.customers;

    const { errors } = this.state;

    const columns = [
      {
        Header: "שם פרטי",
        accessor: "firstName"
      },
      {
        Header: "שם משפחה",
        accessor: "lastName"
      },
      {
        Header: "מספר טלפון",
        accessor: "phoneNumber"
      },
      {
        Header: "ת׳׳ז",
        accessor: "idNumber"
      },
      {
        Header: "E-mail",
        accessor: "email"
      },

      {
        Header: "חובות",
        accessor: "account.total",
        Cell: row => {
          return (
            <span
              className={calssnames("text-success", {
                "text-danger": row.value < 0
              })}
              dir="ltr"
            >
              {" "}
              {row.value.toFixed(0)} ₪
            </span>
          );
        }
      },
      {
        Header: "פרופיל",
        id: "_id",
        accessor: "_id",
        maxWidth: 100,
        Cell: row => {
          return (
            <div>
              <Link to={`profile/${row.row._id}`}>פרופיל</Link>
            </div>
          );
        }
      },
      {
        Header: "ערוך",
        id: "_id",
        accessor: "_id",
        maxWidth: 100,
        Cell: row => {
          return (
            <div>
              <Link to={`customer/${row.row._id}`}>ערוך</Link>
            </div>
          );
        }
      }
    ];

    let content = "";
    if (customers === null || loading) {
      content = <Spinner />;
    } else if (customers.length > 0) {
      content = (
        <div>
          <ReactTable
            loading={loading}
            data={customers}
            columns={columns}
            pageSize={10}
          />
        </div>
      );
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 m-auto">
            <h1 className="display-4 text-center">רשימת לקוחות</h1>

            <div className="container">
              <div className="row">
                <div className="col-md-8 m-auto">
                  <form onSubmit={this.onSubmit}>
                    <InputGroup
                      placeholder="מחפשים לקוח? הקלידו כאן ונמצא לכם"
                      name="search"
                      value={this.state.search}
                      onChange={this.onChange}
                      error={errors.search}
                      icon="fas fa-search"
                    />
                  </form>
                </div>
              </div>
            </div>

            {content}
          </div>
        </div>
      </div>
    );
  }
}
//style={{ height: 100 + "vh" }}
CustomerTable.defaultProps = {};

CustomerTable.propTypes = {
  getCustomers: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  customer: state.customer
});

export default connect(
  mapStateToProps,
  { getCustomers }
)(CustomerTable);
