import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import {
  createCustomer,
  getCustomerById
} from "./customers-duck/customerActions";
import TextFieldGroup from "../../components/common/TextFieldGroup";

import isEmpty from "../../validation/is-empty";

class EditCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      firstName: "",
      lastName: "",
      idNumber: "",
      email: "",
      phoneNumber: "",
      birthDate: "",

      errors: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const {
      match: { params },
      getCustomerById
    } = this.props;
    getCustomerById(params.id);
  }

  onSubmit(e) {
    e.preventDefault();

    const customerData = {
      id: this.state.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      idNumber: this.state.idNumber,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      birthDate: this.state.birthDate
    };
    this.props.createCustomer(customerData, this.props.history, false);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.customer.customer) {
      const customer = nextProps.customer.customer;
      // if customer field doesnt exist, make empty string
      customer.firstName = !isEmpty(customer.firstName)
        ? customer.firstName
        : "";
      customer.lastName = !isEmpty(customer.lastName)
        ? customer.lastName.toString()
        : "";
      customer.idNumber = !isEmpty(customer.idNumber)
        ? customer.idNumber.toString()
        : "";
      customer.email = !isEmpty(customer.email) ? customer.email : "";
      customer.phoneNumber = !isEmpty(customer.phoneNumber)
        ? customer.phoneNumber
        : "";
      customer.birthDate = !isEmpty(customer.birthDate)
        ? customer.birthDate
        : "";
      customer.id = !isEmpty(customer._id) ? customer._id : "";

      this.setState({
        id: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        idNumber: customer.idNumber,
        email: customer.email,
        phoneNumber: customer.phoneNumber,
        birthDate: customer.birthDate
      });
    }
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="create-product">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">עדכון לקוח קיים</h1>
              <p className="lead text-center">טופס עדכון לקוח קיים במערכת</p>
              <small className="d-block pb-3">שדות חובה = *</small>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="* שם פרטי"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.onChange}
                  error={errors.firstName}
                />

                <TextFieldGroup
                  placeholder="* שם משפחה"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.onChange}
                  error={errors.lastName}
                />
                <TextFieldGroup
                  placeholder="מס' ת''ז"
                  name="idNumber"
                  value={this.state.idNumber}
                  onChange={this.onChange}
                  error={errors.idNumber}
                  type="Numbers"
                  info="מספר תעודת זהוי או דרכון"
                />
                <TextFieldGroup
                  placeholder="דואר אליקטרוני"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                  type="email"
                  info="למשל Example@domain.com"
                />
                <TextFieldGroup
                  placeholder="* מס' נייד"
                  name="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.onChange}
                  error={errors.phoneNumber}
                  type="numbers"
                  info="מספר טלפון נייד כולל קידומת"
                />
                <TextFieldGroup
                  placeholder="birthDate"
                  name="birthDate"
                  value={this.state.birthDate}
                  onChange={this.onChange}
                  error={errors.birthDate}
                  type="date"
                />

                <input
                  type="submit"
                  value="ערוך"
                  className="btn btn-info btn-block mt-4"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditCustomer.propTypes = {
  createCustomer: PropTypes.func.isRequired,
  getCustomerById: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  customer: state.customer,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { createCustomer, getCustomerById }
)(withRouter(EditCustomer));
