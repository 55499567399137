import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { DOCUMENT_TYPE } from "../../utils/util";

const DocumentRelations = ({ relations, history }) => {
  return (
    <div className="row">
      <div className="col-md-10 mx-auto">
        <h4>קשרים</h4>
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>סוג מסמך</th>
              <th>צפה</th>
            </tr>
          </thead>
          <tbody>
            {relations.map((e, i) => {
              return (
                <tr key={i}>
                  <td>
                    {e.type === DOCUMENT_TYPE.BID
                      ? "הצעת מחיר"
                      : e.type === DOCUMENT_TYPE.CREDIT
                      ? "זיכוי"
                      : e.type === DOCUMENT_TYPE.INVOICE
                      ? "הזמנה"
                      : e.type === DOCUMENT_TYPE.RECEIPT
                      ? "קבלה"
                      : ""}
                  </td>
                  <td>
                    <Link to={`/document/${e.document}`}>צפה</Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

DocumentRelations.propTypes = {
  relations: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired
};

export default DocumentRelations;
