import axios from "axios";
import { saveAs } from "file-saver";

import {
  ORDER_NOTIFICATION,
  CLEAR_ORDER_NOTIFICATION,
  ORDER_CALENDAR,
  ORDER_LOADING,
  GET_ERRORS
} from "../../../app-duck/actions/types";

// Create Order
export const createOrder = (orderData, history, isNew) => dispatch => {
  axios
    .post("/api/transactions/order/", orderData)
    .then(res => {
      // notifiaction
      dispatch({
        type: ORDER_NOTIFICATION,
        payload: { isNew, data: res.data }
      });

      history.push(`invoice/${res.data._id}`);
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const getOrderBy = orderId => dispatch => {
  axios
    .get(`/api/transactions/order/${orderId}`)
    .then(res => {})
    .catch(err => {});
};

export const createPdfInvoice = (orderData, history) => dispatch => {
  axios
    .post("/api/pdf/create-invoice", orderData)
    .then(res => {
      let fName = res.data.fileName;
      axios
        .get(`/api/pdf/fetch-pdf/${fName}`, {
          method: "GET",
          responseType: "blob"
        })
        .then(res => {
          //Create a Blob from the PDF Stream
          const file = new Blob([res.data], { type: "application/pdf" });
          saveAs(file, fName);
          /*
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);

          //Open the URL on new Window
          window.open(fileURL);
          */
        })
        .catch(err => {
          console.log(err);
        });
    })
    .catch(err => {
      console.log(err);
    });
};

export const getOrderCalendar = () => dispatch => {
  dispatch(setOrderLoading());
  axios
    .get("/api/transactions/orders/calendar")
    .then(res => {
      dispatch({
        type: ORDER_CALENDAR,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Order Loading
export const setOrderLoading = () => {
  return {
    type: ORDER_LOADING
  };
};

// Clear order notification data
export const clearOrderNotification = () => {
  return {
    type: CLEAR_ORDER_NOTIFICATION
  };
};
